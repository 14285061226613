import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./userRegistration.style.css";
import MainPhoto from "../MainPhoto/MainPhoto";
export const UserRegistrationCancel = () => {
    const [error, setError] = useState('');
    const { token } = useParams();

    const cancelRegistration = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}userregistration/cancelregistration/${token}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    
                },
            });

            if (response.ok) {
                const data = await response.json();
                if(data.status === 'User registration removed'){
                    setError('Przykro nam, Twoja rejestracja została usunięta. Zachęcamy do zapoznania się z naszymi innymi wydarzeniami, które mogą Cię zainteresować.');
                }
            } else {
               
                setError('Przepraszamy, wystąpił problem podczas przetwarzania Twojego żądania. Prosimy spróbować ponownie później.');
            }
        } catch (error) {
            
            setError('Nie można nawiązać połączenia z serwerem. Sprawdź swoje połączenie internetowe i spróbuj ponownie.');
        }
    };

    useEffect(() => {
        if (token) {
            cancelRegistration();
        }
    }, [token]);

    return (
      
        <>
         <MainPhoto />
            <div className="contentMessageCancel">
                <div className="topline"></div>
                <div className="messageCancel">{error}</div>
                <div className="bottline"></div>
            </div>
        </>
        
    );
};
