import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Modal } from "../Modal/Modal";
import { PopUpLogin } from "../Login/PopUpLogin/PopupLogin/PopUpLogin";
import { PopUpPasswordReminder } from "../Login/PopUpLogin/PopUpPasswordReminder/PopUpPasswordReminder";
import { PopUpNewPass } from "../Login/PopUpLogin/PopUpNewPass/PopUpNewPass";
import useAPI from "../../hooks/UseAPI";
import MainPhoto from "../MainPhoto/MainPhoto";

export const AdminPanelLogin = () => {
  const { token } = useParams();
  const navigate = useNavigate();
  const [currentView, setCurrentView] = useState("login");

  const adminTokenValidationAPI = useAPI(
    {
      url: `user/passwordresetvalidate/${token}`,
      method: "GET",
    },
    false
  );

  useEffect(() => {
    if (token) {
      adminTokenValidationAPI.call().then((res) => {
        if (res!== undefined && res.response !== undefined &&res?.response.ok) {
          setCurrentView("newPass");
        } else {
          navigate("/");
        }
      });
    } else {
      setCurrentView("login");
    }
  }, [token]);

  const handleForgotPasswordClick = () => {
    setCurrentView("passwordReminder");
  };

  const renderModalContent = () => {
    if (adminTokenValidationAPI.loading) return null;

    switch (currentView) {
      case "login":
        return <PopUpLogin onForgotPassword={handleForgotPasswordClick} />;
      case "passwordReminder":
        return <PopUpPasswordReminder />;
      case "newPass":
        return <PopUpNewPass />;
      default:
        return null;
    }
  };

  return (
    <>
      <MainPhoto />
      <Modal>{renderModalContent()}</Modal>
    </>
  );
};
