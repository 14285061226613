import { useEffect, useState } from "react";
import "./userRegistration.style.css";
import { useParams } from "react-router-dom";
import useAPI from "../../hooks/UseAPI";
import { Modal } from "../Modal/Modal";
import MainPhoto from "../MainPhoto/MainPhoto";
export const UserRegistrationStatus = () => {
    const [registrationInfo, setRegistrationInfo] = useState({ address: '', participants: '', date: '', confirmed: null, elementName: '' });
    const [notFound, setNotFound] = useState(false);

    const { token } = useParams();

    const statusRegistration = useAPI({
        url: `userregistration/getstatus/${token}`,
        method: 'GET',
    });

    useEffect(() => {
        if (token) {
            statusRegistration.call().then((res) => {
               
                if (res.response !== undefined && res.response.ok) {
                    const formattedDate = res.data.elementDateStart.date.slice(0, 16);
                    setRegistrationInfo({
                        confirmed: res.data.confirmed,
                        elementName: `Wydział: ${res.data.elementName}`,
                        address: `Miejsce: ${res.data.elementAddres}`,
                        participants: `Ilość uczestników: ${res.data.participants}`,
                        date: `Data rozpoczęcia: ${formattedDate}`,
                    });
                } else if (res.status === 'no registration found') {
                    setNotFound(true);
                }
            })
        }
    }, [token]);

    return (
        <>
        <MainPhoto />
        <div className="contentMessage">
             
            <div className="message">
                {notFound ? (
                    <div className="notFound">Nie znaleziono użytkownika</div>
                ) : (
                    registrationInfo.confirmed ? (
                        <div className="confirmed">Potwierdzono</div>
                    ) : (
                        <div className="notConfirmed">Nie potwierdzono</div>
                    )
                )}
                {!notFound && (
                    <div className="bodystat">
                        <div className="wydzial">{registrationInfo.elementName}</div>
                        <div>{registrationInfo.address}</div>
                        <div>{registrationInfo.participants}</div>
                        <div className="data">{registrationInfo.date}</div>
                        <div className="line"></div>
                    </div>
                )}
            </div>
        </div>
        </>
    );
};
