import React, { useEffect, useState, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './editPopup.style.css';
import './quill.style.css';
import { Modal } from '../Modal/Modal';
import useAPI from '../../hooks/UseAPI';

type EditPopupProps = {
  showPopup: boolean;
  setShowPopup: (val: boolean) => void;
  eventId: number;
  eventName: string;
  eventDescription: string;
  eventDate: string;
  eventPlace: string;
}

type APIResponse = {
  response?: {
    ok: boolean;
  };
  data?: any;
}

export const EditPopup: React.FC<EditPopupProps> = (props) => {
  const token = sessionStorage.getItem("jwtToken");
  const date = new Date(props.eventDate);
  const formattedDate = `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
  const [eventName, setEventName] = useState(props.eventName || '');
  const [eventPlace, setEventPlace] = useState(props.eventPlace || '');
  const [eventDescription, setEventDescription] = useState(props.eventDescription || '');
  const [eventDate, setEventDate] = useState(formattedDate || '');
  const [visibility, setVisibility] = useState(true);
  const [error, setError] = useState('');
  const popupRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const navbar = document.querySelector('.navbar');
    if (navbar) {
      if (props.showPopup) {
        navbar.classList.add('disabled');
      } else {
        navbar.classList.remove('disabled');
      }
    }

    return () => {
      if (navbar) {
        navbar.classList.remove('disabled');
      }
    };
  }, [props.showPopup]);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
        props.setShowPopup(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [props]);

  const handleVisibilityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setVisibility(event.target.checked);
  };

  function reformatDate(inputDate: string): string {
    let parts = inputDate.split(' ');
    let dateParts = parts[0].split('-');
    let timeParts = parts[1].split(':');
    let date = new Date(parseInt(dateParts[2]), parseInt(dateParts[1]) - 1, parseInt(dateParts[0]), parseInt(timeParts[0]), parseInt(timeParts[1]));

    let year = date.getFullYear();
    let month = ("0" + (date.getMonth() + 1)).slice(-2);
    let day = ("0" + date.getDate()).slice(-2);
    let hours = ("0" + date.getHours()).slice(-2);
    let minutes = ("0" + date.getMinutes()).slice(-2);
    return `${year}-${month}-${day} ${hours}:${minutes}`;
  }

  const editEvent = useAPI({
    url: 'events/editevent',
    method: "POST",
    auth: true,
    token: token || "",
  });

  const handleEditEvent = (e: React.FormEvent) => {
    e.preventDefault();
    setError('');
    const dateRegex = /^\d{2}-\d{2}-\d{4} \d{2}:\d{2}$/;

    if (!dateRegex.test(eventDate)) {
      setError("Format daty jest nieprawidłowy. Wymagany format to: DD-MM-YYYY HH:MM");
    } else {
      editEvent.call({
        body: {
          eventId: props.eventId,
          eventName: eventName,
          eventPlace: eventPlace,
          eventDescription: eventDescription,
          date: reformatDate(eventDate),
          visibility: visibility,
        }
      })
          .then((res: APIResponse) => {
            if(res?.response !== undefined && res.response.ok){
              props.setShowPopup(false);
              window.location.reload();
            } else {
              setError("Upewnij się, czy na pewno data którą wprowadziłeś jest poprawna");
            }
          });
    }
  };

  const Font = ReactQuill.Quill.import('formats/font');
  Font.whitelist = ['sans-serif', 'serif', 'monospace', 'roboto-condensed', 'montserrat', 'open-sans', 'lato'];
  ReactQuill.Quill.register(Font, true);

  const modules = {
    toolbar: [
      [{ 'header': [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', 'strike'],
      [{ 'font': Font.whitelist }],
      [{ 'color': [] }, { 'background': [] }],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      [{ 'align': [] }],
      ['clean']
    ],
  };

  const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike',
    'font', 'color', 'background',
    'list', 'bullet', 'align'
  ];

  if (!props.showPopup) {
    return null;
  }

  return (
      <>
        <div className="popup-overlay" />
        <Modal>
          <div className="popupContent" ref={popupRef}>
            <div className="editForm">
              <div className='headerContainerr'>
                <h2>Edycja Wydarzenia</h2>
                {error && <div className="edit-error">{error}</div>}
              </div>
              <div className='contentBelowLine'>
                <form onSubmit={handleEditEvent}>
                  <div className='textVisibility'>
                    <a>Wydarzenie widoczne dla wszystkich:</a>
                    <div className='checkboxContainer'>
                      <input type='checkbox' className='checkboxVerif' checked={visibility}
                             onChange={handleVisibilityChange}/>
                    </div>
                  </div>
                  <div className='inputBox'>
                    <label>Wydarzenie</label>
                    <input type='text' placeholder='Nazwa Wydarzenia' required value={eventName}
                           onChange={(e) => setEventName(e.target.value)}/>
                  </div>
                  <div className='inputBox'>
                    <label>Miejsce Wydarzenia</label>
                    <input type='text' placeholder='Miejsce wydarzenia' required value={eventPlace}
                           onChange={(e) => setEventPlace(e.target.value)}/>
                  </div>
                  <div className="inputEdit">
                    <label htmlFor="elementDescription">Opis wydarzenia</label>
                    <ReactQuill
                        theme="snow"
                        value={eventDescription}
                        onChange={setEventDescription}
                        modules={modules}
                        formats={formats}
                    />
                  </div>
                  <div className='inputBox'>
                    <label>Data</label>
                    <input type='text' placeholder='DD-MM-YYYY HH:MM' required value={eventDate}
                           onChange={(e) => setEventDate(e.target.value)}/>
                  </div>
                  <button type="submit" className='btn'>Zapisz</button>
                </form>
              </div>
            </div>
          </div>
        </Modal>
      </>
  );
};