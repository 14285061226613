import React, { useState, useEffect, useRef } from "react";
import { EventElementProps } from "./EventElementContainer";
import useAPI from "../../hooks/UseAPI";

type EditElementPopupProps = {
  eventElementId?: number;
  setVisiblePopup: (val: boolean) => void;
  token: string;
  elements: EventElementProps[];
  eventElementsCall: any;
  sortBy: number;
  filter: number;
  faculty: number;
  isAdminOptions: boolean;
  setElements: (ele: EventElementProps[]) => void;
  eventId: number;
  isImportant: boolean;
}

export const EditElementPopup = (props: EditElementPopupProps) => {
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [data, setData] = useState(
      props.elements.find((e: EventElementProps) => e.id === props.eventElementId)
  );
  const [elementType, setElementType] = useState(data?.typeOfElement || "registration");
  const popupRef = useRef<HTMLDivElement>(null);

  const updateElement = useAPI({
    url: "eventelement/updateelement",
    method: "POST",
    auth: true,
    token: props.token,
  });

  const createElement = useAPI({
    url: "eventelement/addelement",
    method: "POST",
    auth: true,
    token: props.token,
  });

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
        props.setVisiblePopup(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [props]);

  const formatDataForUser = (e: string | undefined) => {
    if (e === undefined || e === null) return;
    const date = new Date(e);
    const formattedDate = `${date.getDate().toString().padStart(2, "0")}-${(
        date.getMonth() + 1
    )
        .toString()
        .padStart(2, "0")}-${date.getFullYear()} ${date
        .getHours()
        .toString()
        .padStart(2, "0")}:${date.getMinutes().toString().padStart(2, "0")}`;
    return formattedDate;
  };

  const formatDataForApi = (e: any) => {
    if (e === undefined || e === null) return;
    let parts = e.split(" ");
    let dateParts = parts[0].split("-");
    let timeParts = parts[1].split(":");
    let date = new Date(
        dateParts[2],
        dateParts[1] - 1,
        dateParts[0],
        timeParts[0],
        timeParts[1]
    );

    let year = date.getFullYear();
    let month = ("0" + (date.getMonth() + 1)).slice(-2);
    let day = ("0" + date.getDate()).slice(-2);
    let hours = ("0" + date.getHours()).slice(-2);
    let minutes = ("0" + date.getMinutes()).slice(-2);
    let formattedDate =
        year + "-" + month + "-" + day + " " + hours + ":" + minutes;

    return formattedDate;
  };

  const handleSave = (e: React.FormEvent) => {
    e.preventDefault();
    setError("");
    setMessage("");

    const dateRegex = /^\d{2}-\d{2}-\d{4} \d{2}:\d{2}$/;
    const formData = new FormData(e.target as HTMLFormElement);
    const elementName = formData.get("elementName") as string;
    const faculty = formData.get("faculty") as string;
    const imageId = formData.get("imageId") as string;
    const room = formData.get("room") as string;
    const maxParticipants = formData.get("maxParticipants") as string;
    const startDate = formData.get("startDate") as string;
    const endDate = formData.get("endDate") as string;
    const elementDescription = formData.get("elementDescription") as string;
    const elementType = formData.get("elementType") as string;

    if (!dateRegex.test(startDate) || !dateRegex.test(endDate)) {
      setError("Format daty jest nieprawidłowy. Wymagany format to: DD-MM-YYYY HH:MM");
      return;
    }

    if (elementName.length > 64) {
      setError("Nazwa wydarzenia może mieć maksymalnie 64 znaki");
      return;
    }

    const bodyData = {
      eventElementId: data?.id,
      elementName: elementName,
      elementDescription: elementDescription,
      faculty: faculty,
      visibility: data?.elementVisibility,
      room: room,
      imageId: imageId,
      eventElementStartDateTime: formatDataForApi(startDate),
      eventElementEndDateTime: formatDataForApi(endDate),
      elementType: elementType,
      maxParticipants: maxParticipants,
      eventId: props.eventId,
    };

    const apiCall = props.eventElementId ? updateElement : createElement;

    apiCall
        .call({
          body: props.eventElementId ? bodyData : { ...bodyData, eventId: props.eventId },
        })
        .then((res) => {
          if (res?.response !== undefined && res?.response.ok) {
            if (props.isImportant) {
              fetch(`${process.env.REACT_APP_API_URL}eventelement/getimportantelements/${props.eventId}`, {
                method: 'GET',
                headers: {
                  'Authorization': `Bearer ${props.token}`
                }
              })
                  .then(response => response.json())
                  .then(data => {
                    props.setElements(data);
                    props.setVisiblePopup(false);
                    setMessage("Zmiany zostały zapisane pomyślnie.");
                  })
                  .catch(err => {
                    console.error("Error refreshing important elements:", err);
                    setError("Wystąpił błąd podczas odświeżania ważnych elementów wydarzeń");
                  });
            } else if (typeof props.eventElementsCall?.call === 'function') {
              props.eventElementsCall.call({
                body: {
                  eventId: props.eventId,
                  sortBy: props.sortBy,
                  pageNumber: 1,
                  elementsPerPage: props.isAdminOptions ? 7 : 8,
                  faculty: props.faculty,
                  onlyActive: !props.isAdminOptions,
                  filter: props.filter,
                }
              }).then((res: any) => {
                if (res?.response !== undefined && res.response.ok) {
                  props.setElements(res.data.data);
                  props.setVisiblePopup(false);
                  setMessage("Zmiany zostały zapisane pomyślnie.");
                } else {
                  setError("Wystąpił błąd z odświeżeniem elementów wydarzeń");
                }
              }).catch((err: any) => {
                console.error("Error refreshing elements:", err);
                setError("Wystąpił błąd podczas odświeżania elementów wydarzeń");
              });
            } else {
              console.error("eventElementsCall.call is not a function");
              setError("Nie można odświeżyć listy elementów. Proszę odświeżyć stronę.");
            }
          } else {
            setError("Wystąpił błąd podczas zapisywania zmian. Sprawdź poprawność danych.");
          }
        })
        .catch((err) => {
          console.error("Error saving element:", err);
          setError("Wystąpił nieoczekiwany błąd podczas zapisywania zmian.");
        });
  };

  return (
      <div className="popupEditElementContainer" ref={popupRef}>
        <div className="titleEditElement">
          <h1>Edycja Wydarzenia</h1>
        </div>
        <div className="contentEditElement">
          <form onSubmit={handleSave}>
            <div className="inputEdit">
              <label htmlFor="elementName">Nazwa</label>
              <input
                  type="text"
                  defaultValue={data?.elementName}
                  id="elementName"
                  name="elementName"
                  required
              />
            </div>

            <div className="inputEdit">
              <label htmlFor="faculty">Wydział</label>
              <select
                  defaultValue={data?.faculty}
                  id="faculty"
                  name="faculty"
                  required
              >
                <option value={1}>Nawigacyjny</option>
                <option value={2}>Mechaniczny</option>
                <option value={3}>Zarządzania i Nauk o Jakości</option>
                <option value={4}>Elektryczny</option>
                <option value={6}>Informatyki</option>
                <option value={5}>Wydarzenie Ogólne</option>

              </select>
            </div>

            <div className="inputEdit">
            <label htmlFor="imageId">Rodzaj zajęć</label>
              <select
                  defaultValue={data?.imageId}
                  id="imageId"
                  name="imageId"
                  required
              >
                <option value={1}>Wykład</option>
                <option value={2}>Ćwiczenia</option>
                <option value={3}>Warsztaty</option>
                <option value={4}>Prezentacja</option>
                <option value={5}>Zwiedzanie Wydziału</option>
              </select>
            </div>

            <div className="inputEdit">
              <label htmlFor="elementType">Typ elementu wydarzenia</label>
              <select
                  value={elementType}
                  onChange={(e) => setElementType(e.target.value)}
                  id="elementType"
                  name="elementType"
                  required
              >
                <option value="registration">Zapis</option>
                <option value="information">Informacyjne</option>
              </select>
            </div>

            <div className="inputEdit">
              <label htmlFor="room">Miejsce</label>
              <input
                  defaultValue={data?.room}
                  type="text"
                  id="room"
                  name="room"
                  required
              />
            </div>

            {elementType === "registration" && (
                <div className="inputEdit">
                  <label htmlFor="maxParticipants">Ilość dostępnych miejsc</label>
                  <input
                      type="text"
                      id="maxParticipants"
                      name="maxParticipants"
                      defaultValue={data?.maxParticipants}
                  />
                </div>
            )}

            <div className="inputEdit">
              <label htmlFor="startDate">Data rozpoczęcia</label>
              <input
                  placeholder="DD-MM-YYYY HH:MM"
                  defaultValue={formatDataForUser(data?.eventElementStartDateTime)}
                  type="text"
                  id="startDate"
                  name="startDate"
                  required
              />
            </div>

            <div className="inputEdit">
              <label htmlFor="endDate">Data zakończenia</label>
              <input
                  placeholder="DD-MM-YYYY HH:MM"
                  defaultValue={formatDataForUser(data?.eventElementEndDateTime)}
                  type="text"
                  id="endDate"
                  name="endDate"
                  required
              />
            </div>

            <div className="inputEdit">
              <label htmlFor="elementDescription">Opis</label>
              <textarea
                  id="elementDescription"
                  name="elementDescription"
                  defaultValue={data?.elementDescription}
              />
            </div>

            <div style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "30px",
            }}>
              <button type="submit" className="buttonSave">
                Zapisz
              </button>
            </div>
          </form>
          {message && <div className="success">{message}</div>}
          {error && <div className="error">{error}</div>}
        </div>
      </div>
  );
};
