import React, { useState, useEffect } from 'react';
import { EventElementContainer, EventElementProps } from './EventElementContainer';
import { IoIosArrowDown, IoIosArrowUp } from 'react-icons/io';
import useAPI from '../../hooks/UseAPI';
import './importantEventElements.style.css';

interface ImportantEventElementsProps {
    eventId: number;
    isAdminOptions: boolean;
    showWarning: (message: string) => void;
}

export const ImportantEventElements: React.FC<ImportantEventElementsProps> = ({ eventId, isAdminOptions, showWarning }) => {
    const [isVisible, setIsVisible] = useState(true);
    const [elements, setElements] = useState<EventElementProps[]>([]);
    const token = sessionStorage.getItem("jwtToken");

    const importantEventElementsData = useAPI(
        {
            url: `eventelement/getimportantelements/${eventId}`,
            method: "GET",
            auth: true,
            token: token ? token : "",
        },
        true
    );

    useEffect(() => {
        if (importantEventElementsData.data) {
            setElements(importantEventElementsData.data);
        }
    }, [importantEventElementsData.data]);

    if (importantEventElementsData.loading || importantEventElementsData.error || elements.length === 0) {
        return null;
    }

    const updateElementVisibility = (id: number, visibility: boolean) => {
        setElements(prevElements =>
            prevElements.map(el => el.id === id ? { ...el, elementVisibility: visibility } : el)
        );
    };

    const updateElementImportance = async (id: number, importance: boolean) => {
        if (!importance) {
            // Remove the element from the list if it's no longer important
            setElements(prevElements => prevElements.filter(el => el.id !== id));
        } else {
            setElements(prevElements =>
                prevElements.map(el => el.id === id ? { ...el, important: importance } : el)
            );
        }
    };

    return (
        <div className="important-event-elements">
            <div className="important-event-elements-header" onClick={() => setIsVisible(!isVisible)}>
                <h2>POLECANE WYDARZENIA</h2>
                {isVisible ? <IoIosArrowUp /> : <IoIosArrowDown />}
            </div>
            {isVisible && (
                <div className="important-event-elements-list">
                    {elements.map((element: EventElementProps, index: number) => (
                        <div
                            key={element.id}
                            className={`event-element-wrapper ${
                                index === elements.length - 1 && elements.length % 2 !== 0 ? 'wide' : ''
                            }`}
                        >
                            <EventElementContainer
                                {...element}
                                isAdminOptions={isAdminOptions}
                                elements={elements}
                                setElements={setElements}
                                updateElementVisibility={updateElementVisibility}
                                updateElementImportance={updateElementImportance}
                                showWarning={showWarning}
                                isImportant={true}
                            />
                        </div>
                    ))}
                </div>
            )}
        </div>
    );
};

export default ImportantEventElements;
