import React from 'react';
import { Modal } from "../Modal/Modal";

interface WarningPopupProps {
    message: string;
    onClose: () => void;
}

export const WarningPopup: React.FC<WarningPopupProps> = ({ message, onClose }) => {
    return (
        <Modal>
            <div className="warning-popup">
                <p>{message}</p>
                <button onClick={onClose}>OK</button>
            </div>
        </Modal>
    );
};