import React, { useState } from "react";
import "./userRegistration.style.css";
import MainPhoto from "../MainPhoto/MainPhoto";

interface UserRegistrationInfo {
    status: string;
    retry_after?: number;
}

export const UserRegistrationToken: React.FC = () => {
    const [email, setEmail] = useState<string>('');
    const [responseInfo, setResponseInfo] = useState<UserRegistrationInfo | null>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);

    const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(e.target.value);
        // Reset states when email changes
        setResponseInfo(null);
        setError(null);
    };

    const checkRegistration = async () => {
        setIsLoading(true);
        setError(null);
        setResponseInfo(null);

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}userregistration/getuserinformationtoken/${email}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            const data = await response.json();

            if (response.status === 200) {
                setResponseInfo(data);
            } else if (response.status === 429) {
                setError("Przekroczono limit prób. Spróbuj ponownie później.");
            } else if (response.status === 400) {
                if (data.status === 'missing parameters') {
                    setError("Brak wymaganych parametrów.");
                } else if (data.status === 'no registrations found') {
                    setError("Nie znaleziono rejestracji dla podanego adresu email.");
                } else if (data.status === 'mailing error') {
                    setError("Wystąpił błąd podczas wysyłania wiadomości email.");
                } else {
                    setError("Wystąpił błąd podczas przetwarzania żądania.");
                }
            } else {
                setError("Wystąpił nieoczekiwany błąd.");
            }
        } catch (error) {
            setError("Wystąpił błąd podczas komunikacji z serwerem.");
        } finally {
            setIsLoading(false);
        }
    };

    const isEmailValid = (email: string) => {
        return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
    };

    const getMessageContent = () => {
        if (error) {
            return <div className="errorMessage">{error}</div>;
        }

        if (responseInfo?.status === 'email sent succesfully') {
            return (
                <div className="successMessage">
                    <p>Link do sprawdzenia Twoich rejestracji został wysłany na podany adres email.</p>
                    <p>Link jest ważny przez 30 minut.</p>
                </div>
            );
        }

        return (
            <div className="registrationInfoText">
                W celu uzyskania informacji na temat swoich rejestracji, proszę podać adres email.
            </div>
        );
    };

    return (
        <>
            <MainPhoto />
            <div className="registrationCheckContainer">
                <div className="registrationCheckContent">
                    <h2>Sprawdź swoje rejestracje</h2>
                    <input
                        type="email"
                        value={email}
                        onChange={handleEmailChange}
                        placeholder="Wpisz swój adres email"
                        className="emailInput"
                        disabled={isLoading || responseInfo?.status === 'email sent succesfully'}
                    />

                    {getMessageContent()}

                    <button
                        onClick={checkRegistration}
                        className="checkButton"
                        disabled={!isEmailValid(email) || isLoading || responseInfo?.status === 'email sent succesfully'}
                    >
                        {isLoading ? 'SPRAWDZANIE...' : 'SPRAWDŹ ZAPISY'}
                    </button>
                </div>
            </div>
        </>
    );
};

export default UserRegistrationToken;