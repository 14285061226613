// src/components/Notifications/NotificationsDisable.tsx
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useAPI from '../../hooks/UseAPI';
import MainPhoto from '../MainPhoto/MainPhoto';

export const NotificationsDisable = () => {
    const [message, setMessage] = useState('');
    const { token } = useParams();

    const disableNotifications = useAPI({
        url: `email/stopnotifications/${token}`,
        method: 'GET',
    });

    useEffect(() => {
        const handleDisable = async () => {
            try {
                const result = await disableNotifications.call();

                if (result?.response?.ok) {
                    setMessage('Wyłączono powiadomienia o nowych wydarzeniach. Mamy nadzieję, że wkrótce do nas wrócisz!');
                } else {
                    if (result?.data?.error === 'invalid code') {
                        setMessage('Nieprawidłowy kod wypisania z newslettera. Sprawdź, czy link jest poprawny.');
                    } else {
                        setMessage('Przepraszamy, wystąpił problem podczas przetwarzania Twojego żądania. Prosimy spróbować ponownie później.');
                    }
                }
            } catch (error) {
                setMessage('Nie można nawiązać połączenia z serwerem. Sprawdź swoje połączenie internetowe i spróbuj ponownie.');
            }
        };

        if (token) {
            handleDisable();
        }
    }, [token]);

    return (
        <>
            <MainPhoto />
            <div className="contentMessageCancel">
                <div className="topline"></div>
                <div className="messageCancel">{message}</div>
                <div className="bottline"></div>
            </div>
        </>
    );
};