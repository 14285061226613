import { SlClose, SlWrench } from "react-icons/sl";
import { SlCheck } from "react-icons/sl";
import { EventElementProps } from "../Events/EventElementContainer";
import useAPI from "../../hooks/UseAPI";
import { useState } from "react";
import { DeleteElementPopup } from "../Events/DeleteElementPopup";
import { Modal } from "../Modal/Modal";
import { IoBookOutline } from "react-icons/io5";
import { MdComputer } from "react-icons/md";
import { GrWorkshop } from "react-icons/gr";
import { FaPersonWalking } from "react-icons/fa6";

export type EventElementConfirmProps = {
  registrationData: RegistrationData;
  eventElementData: EventElementProps;
  data: any;
  setData: (val: any) => void;
};

type RegistrationData = {
  id: number;
  email: string;
  name: string;
  lastname: string;
  eventId: number;
  eventElementId: number;
  groupBool: boolean;
  groupSize: number;
  confirmed: boolean;
  confirmedGroup: boolean;
  confirmationCode: string;
};

export const EventElementConfirm = (props: EventElementConfirmProps) => {
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [visibleDeletePopup, setVisibleDeletePopup] = useState<boolean>(false);

  const [dateStartPart, timeStartPart] =
    props.eventElementData.eventElementStartDateTime.split("T");
  const [dateEndPart, timeEndPart] =
    props.eventElementData.eventElementEndDateTime.split("T");
  const token = sessionStorage.getItem("jwtToken");

  const elementConfirm = () => {
    switch (props.registrationData.confirmedGroup) {
      case true:
        return (
          <SlCheck style={{ width: "20px", height: "20px", color: "#fff" }} />
        );
      case false:
        return (
          <SlClose style={{ width: "20px", height: "20px", color: "#fff" }} />
        );
      default:
        return null;
    }
  };

  const cancelRegistration = useAPI({
    url: `userregistration/cancelregistration/${props.registrationData.confirmationCode}`,
    method: "GET",
    auth: true,
    token: token ? token : "",
  });

  const confirmRegistration = useAPI({
    url: `userregistration/confirmgroupregistration`,
    method: "POST",
    auth: true,
    token: token ? token : "",
  });

  const truncateString = (str: string, maxLength: number) => {
    if (str.length <= maxLength) {
      return str;
    } else {
      const truncatedString = str.substring(0, maxLength);
      const lastSpaceIndex = truncatedString.lastIndexOf(" ");
      return truncatedString.substring(0, lastSpaceIndex) + "...";
    }
  };

  const truncatedDescription = truncateString(
    props.eventElementData.elementDescription,
    50
  );

  const eventLogo = () => {
    switch (props.eventElementData.imageId) {
      case 1:
        return <IoBookOutline style={{ width: "100px", height: "100px" }}/>;
      case 2:
        return <MdComputer style={{ width: "100px", height: "100px" }}/>;
      case 3:
        return <SlWrench style={{ width: "100px", height: "100px" }}/>;
      case 4:
        return <GrWorkshop style={{ width: "100px", height: "100px" }}/>;
      case 5:
        return <FaPersonWalking style={{ width: "100px", height: "100px", fill:"none",stroke: "while",strokeWidth: "20",overflow: "visible"}} />;
    }
  };

  const handleDeleteClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setVisibleDeletePopup(true);
  };

  return (
    <>
      {visibleDeletePopup && (
        <>
          <Modal>
            <DeleteElementPopup
              eventElementId={props.registrationData.confirmationCode}
              eventElementName={props.eventElementData.elementName}
              dateStartPart={dateStartPart}
              timeStartPart={timeStartPart}
              timeEndPart={timeEndPart}
              setVisiblePopup={() => setVisibleDeletePopup(false)}
              elements={props.data}
              setElements={props.setData}
              token={token ? token : ""}
              deleteCall={cancelRegistration}
              error={error}
              setError={setError}
            />
          </Modal>
        </>
      )}
      <div className="eventElementConfirm">
        <button
          className="confirmationStatus"
          style={{
            backgroundColor:
              props.registrationData.confirmedGroup === true
                ? "rgba(0, 166, 56, 1)"
                : "rgba(166, 0, 0, 1)",
          }}
        >
          {elementConfirm()}
        </button>
        <div className="leftSide">
          {eventLogo()}
          <h4>{props.eventElementData.elementName}</h4>
          <p style={{ fontSize: "14px" }}>{truncatedDescription}</p>
        </div>
        <div className="rightSide">
          <p>{dateStartPart}</p>
          <p>
            {timeStartPart.split("+")[0].substring(0, 5)} -{" "}
            {timeEndPart.split("+")[0].substring(0, 5)}
          </p>
          <p>Miejsc zostało: {props.eventElementData.participants}</p>
          <br />
          {props.registrationData.confirmedGroup === true && (
            <button className="btn" onClick={handleDeleteClick}>
              <b>Usuń</b>
            </button>
          )}

          <button
            className="btn"
            onClick={() =>
              confirmRegistration
                .call({
                  body: {
                    userRegistrationId: props.registrationData.id,
                    email: props.registrationData.email,
                  },
                })
                .then((res) => {
                  if (res?.response !== undefined && res.response.ok) {
                    setMessage("Zweryfikowano poprawnie rejestrację");
                    setError("");
                    const newData = props.data.registrationData.find(
                      (e: RegistrationData) => {
                        if (e.id === props.registrationData.id) {
                          e.groupBool = true;
                        }
                      }
                    );
                    props.setData({ ...props.data, ...newData });
                  } else {
                    if (
                      res?.status !== undefined &&
                      res.status === "group not confirmed by email"
                    ) {
                      setError("Użytkownik nie zweryfikował maila na poczcie");
                      setMessage("");
                    } else {
                      setError("Wystąpił błąd podczas usuwania rejestracji");
                      setMessage("");
                    }
                  }
                })
            }
          >
            <b>Zweryfikuj</b>
          </button>
          {message && <div className="succes">{message}</div>}
          {error && <div className="error">{error}</div>}
        </div>
      </div>
    </>
  );
};
