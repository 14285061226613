import React from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import useAPI from '../../hooks/UseAPI';
import './notification.style.css';

interface NotificationEditorProps {
    onClose: () => void;
    onUpdate: () => void;
    content: string;
    setContent: React.Dispatch<React.SetStateAction<string>>;
}

const NotificationEditor: React.FC<NotificationEditorProps> = ({ onClose, onUpdate, content, setContent }) => {
    const token = sessionStorage.getItem("jwtToken");

    const updateNotificationAPI = useAPI({
        url: "welcomepage/editinstructioncontent",
        method: "POST",
        auth: true,
        token: token ? token : "",
    });

    const handleSave = () => {
        updateNotificationAPI.call({
            body: {
                message: content
            }
        }).then((res) => {
            if (res?.response && res.response.ok) {
                onUpdate();
                onClose();
            }
        });
    };

    const Font = ReactQuill.Quill.import('formats/font');
    Font.whitelist = ['sans-serif', 'serif', 'monospace', 'roboto-condensed', 'montserrat', 'open-sans', 'lato'];
    ReactQuill.Quill.register(Font, true);

    const modules = {
        toolbar: [
            [{ 'header': [1, 2, 3, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'font': Font.whitelist }],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'align': [] }],
            ['clean']
        ],
    };

    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike',
        'font', 'color', 'background',
        'list', 'bullet', 'align'
    ];

    return (
        <div className="notification-editor">
            <ReactQuill
                theme="snow"
                value={content}
                onChange={setContent}
                modules={modules}
                formats={formats}
            />
            <div className="editor-actions">
                <button onClick={handleSave} className="save-button">Save</button>
                <button onClick={onClose} className="cancel-button">Cancel</button>
            </div>
        </div>
    );
};

export default NotificationEditor;