import { useEffect, useState } from 'react';
import {Participant} from './ListElementPopup';
import useAPI from '../../hooks/UseAPI';
import { EventElementProps } from './EventElementContainer';

type DeleteUserPopupProps = {
    participant: Participant;
    eventElementName: string;
    dateStartPart: string;
    timeStartPart: string;
    timeEndPart: string;
    setVisiblePopup: (val: boolean) => void;
    eventElementId: number | string;
    elements: EventElementProps[];
    setElements: (ele:EventElementProps[]) => void;
};


export const DeleteUserPopup = (props: DeleteUserPopupProps) => {
    const [error, setError] = useState('');

    const cancelregistration = useAPI({
        url:`userregistration/cancelregistration/${props.participant.confirmationCode}`,
        method: 'GET',
    })

    const cancelRegistration = async () => {
        cancelregistration.call().then((res)=>{
            if(res?.response !== undefined && res.response.ok){
                const updatedElements = props.elements.map((element) => {
                    if (element.id === props.eventElementId) {
                      const updatedElement = {
                        ...element,
                        participants: typeof element.participants === 'number' ? element.participants + res.data.groupSize : parseInt(element.participants) + res.data.groupSize
                      };
                      return updatedElement;
                    }
                    return element;
                  });
                props.setElements(updatedElements)
                props.setVisiblePopup(false)
            }else{
                setError('Wystąpił problem podczas usuwania rejestracji');
            }
        })
    };


    const formatTimePart = (timePart:string) => {
        const [time,] = timePart.split("+");
        return time.slice(0,5);
    }
    useEffect(() => {
        const handleClickOutside = (event:any) => {
          const popupElement = document.querySelector(".deleteUserEvent");
          if (popupElement && !popupElement.contains(event.target)) {
            props.setVisiblePopup(false);
          }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => document.removeEventListener("mousedown", handleClickOutside);
      }, []);

    return (
        <div className='deleteUserEvent' onClick={(e) => e.stopPropagation()}>
            <div className='titleDeleteUserEvent'>
                <h2>Czy napewno chcesz usunąć rejestrację z wydarzenia?</h2>
                <div className='error'>{error}</div>
            </div>
            <div className='contentDeleteUserEvent'>
                <h3> {props.eventElementName}</h3>
                <h3> {props.dateStartPart}</h3>
                <h3>{formatTimePart(props.timeStartPart)} - {formatTimePart(props.timeEndPart)}</h3>
                <h3> {props.participant.email}</h3>
                <h3> {props.participant.name} {props.participant.lastname}</h3>
            </div>
            <div className='deleteUserActions'>
            <button style={{ backgroundColor: "rgba(166, 0, 0, 1)" }} onClick={cancelRegistration}>Usuń</button>
          <button  style={{ backgroundColor: "rgba(21, 33, 61, 1)" }} onClick={() => props.setVisiblePopup(false)}>Powrót</button>
            </div>
        </div>
    );
};
