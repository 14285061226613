import React, { useState } from 'react';
import { format, startOfWeek, addDays, startOfMonth, isSameMonth, isSameDay, parseISO } from 'date-fns';
import { pl } from 'date-fns/locale';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';

interface Event {
    id: number;
    eventDate: string;
}

interface CalendarProps {
    events: Event[];
    onSelectDate: (date: Date | null) => void;
    selectedDate: Date | null;
    onMonthChange: (date: Date) => void;
}

export const Calendar: React.FC<CalendarProps> = ({ events, onSelectDate, selectedDate, onMonthChange }) => {
    const [currentMonth, setCurrentMonth] = useState(new Date());

    const renderDays = () => {
        const dateFormat = "d";
        const days = [];
        let startDate = startOfWeek(startOfMonth(currentMonth), { weekStartsOn: 1 });

        for (let i = 0; i < 42; i++) {
            const day = addDays(startDate, i);
            const cloneDay = day;
            days.push(
                <div
                    className={`calendar-day ${
                        !isSameMonth(day, currentMonth) ? "outside-month" : ""
                    } ${selectedDate && isSameDay(day, selectedDate) ? "selected" : ""}`}
                    key={day.toString()}
                    onClick={() => onSelectDate(cloneDay)}
                >
                    <span className="date-number">{format(day, dateFormat)}</span>
                    {getEventCountForDay(day) > 0 && (
                        <div className="event-dots">
                            {[...Array(Math.min(getEventCountForDay(day), 6))].map((_, i) => (
                                <span key={i} className={`event-dot event-dot-${i + 1}`}></span>
                            ))}
                        </div>
                    )}
                </div>
            );
        }

        return days;
    };

    const getEventCountForDay = (date: Date) => {
        return events.filter(event => {
            try {
                return isSameDay(parseISO(event.eventDate), date);
            } catch (error) {
                console.error("Error parsing date:", error);
                return false;
            }
        }).length;
    };

    const changeMonth = (increment: number) => {
        const newMonth = new Date(currentMonth.getFullYear(), currentMonth.getMonth() + increment, 1);
        setCurrentMonth(newMonth);
        onMonthChange(newMonth);
    };

    return (
        <div className="calendar">
            <div className="calendar-header">
                <button className="month-nav-btn" onClick={() => changeMonth(-1)}><FaChevronLeft/></button>
                <h2>{format(currentMonth, 'LLLL yyyy', {locale: pl})}</h2>
                <button className="month-nav-btn" onClick={() => changeMonth(1)}><FaChevronRight/></button>
            </div>
            <div className="calendar-grid">
                {renderDays()}
            </div>
        </div>
    );
};

export default Calendar;