import React, { useEffect, useState, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import '../EditPopup/editPopup.style.css';
import '../EditPopup/quill.style.css';
import useAPI from '../../hooks/UseAPI';

type CreateEventPopupProps = {
    showPopup: boolean;
    setShowPopup: (val: boolean) => void;
    onEventCreated: () => void;
}

type APIResponse = {
    response?: {
        ok: boolean;
    };
    data?: any;
}

export const CreateEventPopup: React.FC<CreateEventPopupProps> = (props) => {
    const token = sessionStorage.getItem("jwtToken");
    const [eventName, setEventName] = useState('');
    const [eventPlace, setEventPlace] = useState('');
    const [eventDescription, setEventDescription] = useState('');
    const [eventDate, setEventDate] = useState('');
    const [visibility, setVisibility] = useState(false);
    const [error, setError] = useState('');
    const popupRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        const navbar = document.querySelector('.navbar');
        if (navbar) {
            if (props.showPopup) {
                navbar.classList.add('disabled');
            } else {
                navbar.classList.remove('disabled');
            }
        }
        return () => {
            if (navbar) {
                navbar.classList.remove('disabled');
            }
        };
    }, [props.showPopup]);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
                props.setShowPopup(false);
                setError("");
            }
        };
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [props]);

    const handleVisibilityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setVisibility(event.target.checked);
    };

    const handleClosePopup = () => {
        props.setShowPopup(false);
    };

    function formatDate(inputDate: string): string {
        let date = new Date(inputDate);
        let year = date.getFullYear();
        let month = ("0" + (date.getMonth() + 1)).slice(-2);
        let day = ("0" + date.getDate()).slice(-2);
        let hours = ("0" + date.getHours()).slice(-2);
        let minutes = ("0" + date.getMinutes()).slice(-2);
        return `${year}-${month}-${day} ${hours}:${minutes}`;
    }

    const createEvent = useAPI({
        url: 'events/addevent',
        method: "POST",
        auth: true,
        token: token || "",
    });

    const handleCreateEvent = (e: React.FormEvent) => {
        e.preventDefault();
        setError('');

        if (!eventDate) {
            setError("Proszę wybrać datę i godzinę wydarzenia.");
            return;
        }

        createEvent.call({
            body: {
                eventName: eventName,
                eventPlace: eventPlace,
                eventDescription: eventDescription,
                date: formatDate(eventDate),
                visibility: visibility,
            }
        })
            .then((res: APIResponse) => {
                if (res?.response?.ok) {
                    props.setShowPopup(false);
                    props.onEventCreated();
                } else {
                    setError("Nie udało się utworzyć wydarzenia. Spróbuj ponownie.");
                }
            })
            .catch(() => {
                setError("Wystąpił błąd podczas tworzenia wydarzenia.");
            });
    };

    const Font = ReactQuill.Quill.import('formats/font');
    Font.whitelist = ['sans-serif', 'serif', 'monospace', 'roboto-condensed', 'montserrat', 'open-sans', 'lato'];
    ReactQuill.Quill.register(Font, true);

    const modules = {
        toolbar: [
            [{ 'header': [1, 2, 3, false] }],
            ['bold', 'italic', 'underline', 'strike'],
            [{ 'font': Font.whitelist }],
            [{ 'color': [] }, { 'background': [] }],
            [{ 'list': 'ordered'}, { 'list': 'bullet' }],
            [{ 'align': [] }],
            ['clean']
        ],
    };

    const formats = [
        'header',
        'bold', 'italic', 'underline', 'strike',
        'font', 'color', 'background',
        'list', 'bullet', 'align'
    ];

    if (!props.showPopup) {
        return null;
    }

    return (
        <>
            <div className="popup-overlay" />
            <div className="popupContent" ref={popupRef}>
                <div className="editForm">
                    <div className="headerContainerr">
                        <button className="close-button" onClick={handleClosePopup}>
                            X
                        </button>
                        <h2>Tworzenie nowego wydarzenia</h2>
                        {error && <div className="edit-error">{error}</div>}
                    </div>
                    <div className='contentBelowLine'>
                        <form onSubmit={handleCreateEvent}>
                            <div className='textVisibility'>
                                <a>Wydarzenie widoczne dla wszystkich:</a>
                                <div className='checkboxContainer'>
                                    <input type='checkbox' className='checkboxVerif' checked={visibility}
                                           onChange={handleVisibilityChange}/>
                                </div>
                            </div>
                            <div className='inputBox'>
                                <label>Wydarzenie</label>
                                <input type='text' placeholder='Nazwa Wydarzenia' required value={eventName}
                                       onChange={(e) => setEventName(e.target.value)}/>
                            </div>
                            <div className='inputBox'>
                                <label>Miejsce wydarzenia</label>
                                <input type='text' placeholder='Miejsce wydarzenia' required value={eventPlace}
                                       onChange={(e) => setEventPlace(e.target.value)}/>
                            </div>
                            <div className="inputEdit">
                                <label htmlFor="elementDescription">Opis wydarzenia</label>
                                <ReactQuill
                                    theme="snow"
                                    value={eventDescription}
                                    onChange={setEventDescription}
                                    modules={modules}
                                    formats={formats}
                                />
                            </div>
                            <div className='inputBox'>
                                <label>Data</label>
                                <input type='datetime-local' required value={eventDate}
                                       onChange={(e) => setEventDate(e.target.value)}/>
                            </div>
                            <button type="submit" className='btn'>Utwórz Wydarzenie</button>
                        </form>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CreateEventPopup;