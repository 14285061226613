import React, { useEffect, useState, useRef, useCallback } from "react";
import "./editPopup.style.css";
import { Modal } from "../Modal/Modal";
import useAPI from "../../hooks/UseAPI";

type EditPopupProps = {
  showPopup: boolean;
  setShowPopup: (val: boolean) => void;
  upperPopUpText?: string;
  lowerPopUpText?: string;
  eventId: number;
};

export const EditInfoPopup: React.FC<EditPopupProps> = (props) => {
  const token = sessionStorage.getItem("jwtToken");
  const [error, setError] = useState("");
  const popupRef = useRef<HTMLDivElement>(null);

  const editEvent = useAPI({
    url: "events/setpopup",
    method: "POST",
    auth: true,
    token: token ? token : "",
  });

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
        props.setShowPopup(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [props]);

  const handleEditEvent = useCallback((e: React.FormEvent) => {
    setError("");
    e.preventDefault();
    const formData = new FormData(e.target as HTMLFormElement);
    const upper = formData.get("upperText") as string;
    const lower = formData.get("lowerText") as string;
    if (upper.length <= 64 && lower.length <= 64) {
      editEvent
          .call({
            body: {
              eventId: props.eventId,
              upperPopUpText: upper,
              lowerPopUpText: lower,
            },
          })
          .then((res) => {
            if (res?.response !== undefined && res?.response.ok) {
              props.setShowPopup(false);
              window.location.reload();
            } else {
              setError("Upewnij się, czy na pewno wprowadziłeś poprawne dane");
            }
          });
    } else {
      setError("Długość tekstów nie powinna przekraczać 64 znaków");
    }
  }, [editEvent, props]);

  if (!props.showPopup) {
    return null;
  }

  return (
      <Modal>
        <div className="popupContent" ref={popupRef}>
          <div className="editForm">
            <div className="headerContainerr">
              <h2>Edycja Popupu Wydarzenia</h2>
              {error && <div className="edit-error">{error}</div>}
            </div>
            <div className="contentBelowLine">
              <form onSubmit={handleEditEvent}>
                <div className="inputBox">
                  <label htmlFor="upperText">Górny tekst</label>
                  <input
                      type="text"
                      id="upperText"
                      name="upperText"
                      placeholder="Górny tekst"
                      defaultValue={props.upperPopUpText}
                      required
                      maxLength={64}
                  />
                </div>

                <div className="inputBox">
                  <label htmlFor="lowerText">Dolny tekst</label>
                  <input
                      type="text"
                      id="lowerText"
                      name="lowerText"
                      placeholder="Dolny tekst"
                      defaultValue={props.lowerPopUpText}
                      required
                      maxLength={64}
                  />
                </div>

                <button type="submit" className="btn">
                  Zapisz
                </button>
              </form>
            </div>
          </div>
        </div>
      </Modal>
  );
};