import { Link } from "react-router-dom";
import logo from '../../assets/Logo_navbar.png';
import logoScrolled from '../../assets/Logo-umg-scrolled.png';
import logo1 from '../../assets/logo_białe.png';
import logoScrolled1 from '../../assets/logo_szare.png';
import "../Navbar/navbar.style.css";
import useAPI from "../../hooks/UseAPI";
import { useEffect, useState } from "react";
<link href="https://fonts.googleapis.com/css2?family=Roboto+Condensed:wght@500&display=swap" rel="stylesheet"></link>

interface Props {
  isScrolled: boolean;
}

const Navbar = ({ isScrolled }: Props) => {
  const navbarClasses = isScrolled ? "navbar scrolled" : "navbar notscrolled";
  const buttonClasses = isScrolled ? "buttonlog scrolled" : "buttonlog notscrolled";
  const token = sessionStorage.getItem("jwtToken");
  const [showAdminOptions, setShowAdminOptions] = useState(false);
  const tokenValidate = useAPI({
    url: "user/validatetoken",
    method: "GET",
    auth: true,
    token: token ? token : "",
  });
  const handleLogout = () =>{
    sessionStorage.clear();
    window.location.reload();
  }

  useEffect(() => {
    if (token) {
      tokenValidate.call().then((res) => {
        if (res?.response !== undefined && res.response.ok) {
          setShowAdminOptions(true);
        } else {
          setShowAdminOptions(false);
        }
      });
    }
  }, [token]);

  return (
      <div className={navbarClasses}>
        <nav>
          <Link to="/" className="div930213">
            <img className="logo" src={isScrolled ? logoScrolled1 : logo1} alt="Logo" />
          </Link>

          <div className="text">
            <Link to="/">UNIWERSYTET MORSKI W GDYNI</Link>
          </div>
          <div className="textMobile">
            <Link to="/">UMG</Link>
          </div>

          <div className="navbar right">
            {showAdminOptions && (
                <button className={buttonClasses} onClick={handleLogout}>WYLOGUJ</button>
            )}
            {!showAdminOptions && (
                <>
                  <Link to="/notifications" className="sprawdz-zapisy">
                    WŁĄCZ POWIADOMIENIA
                  </Link>
                  <Link to="/checkregistration" className="sprawdz-zapisy">
                    MOJE ZAPISY
                  </Link>
                </>
            )}
          </div>
        </nav>
      </div>
  );
};

export default Navbar;