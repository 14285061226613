export type DepartmentType = {
    name: string;
    image?: string;
    id: number;
};

type DepartmentBoxProps = {
    name: string;
    image?: string;
    id: number;
} & DepartmentsContainerProps

type DepartmentsContainerProps = {
    departmentList: DepartmentType[];
    filterPages: any;
    faculty: number;
    setFaculty: (val: number) => void;
    sortBy: number;
    filter: number
};

const DepartmentBox = (props: DepartmentBoxProps) => {
    const isFacultyActive = props.faculty === props.id;
    const isShowAllButton = props.id === 0;

    const darkBlueColor = "rgba(21, 33, 61, 1)";
    const showAllBoxShadow = "0px 0px 10px rgba(21, 33, 61, 0.8)";
    const departmentBoxShadow = "0px 0px 10px rgba(21, 33, 61, 0.8)";

    return (
        <div
            onClick={() => {
                props.filterPages(1, props.sortBy, props.id, props.filter);
                props.setFaculty(props.id);
            }}
            className={isShowAllButton ? 'department-show-all-events-container' : ''}
            style={{
                boxShadow: isShowAllButton && isFacultyActive ? showAllBoxShadow : "none",
                backgroundColor: isShowAllButton && isFacultyActive ? darkBlueColor : "transparent"
            }}
        >
            {!isShowAllButton && props.image && (
                <div
                    className="departmentImage"
                    style={{
                        boxShadow: isFacultyActive ? departmentBoxShadow : "none"
                    }}
                >
                    <img src={props.image} alt={props.name} />
                </div>
            )}
            <div className={`departmentName ${isShowAllButton ? 'department-show-all-events-button' : ''}`}>
                <h4>{props.name}</h4>
            </div>
        </div>
    );
};

export const DepartmentsContainer = (props: DepartmentsContainerProps) => {
    return (
        <>
            {props.departmentList &&
                props.departmentList.map((d: DepartmentType) => {
                    return (
                        <DepartmentBox key={d.id} {...d} {...props} />
                    );
                })}
        </>
    );
};
