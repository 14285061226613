import React, { useState } from 'react';
import useAPI from '../../hooks/UseAPI';
import './copyEvent.style.css';
import { format } from 'date-fns';
interface CopyEventProps {
    eventId: number;
    onClose: () => void;
    onCopy: () => void;
}

const CopyEvent: React.FC<CopyEventProps> = ({ eventId, onClose, onCopy }) => {
    const [eventDate, setEventDate] = useState('');
    const [copyElements, setCopyElements] = useState(false);
    const [confirmationText, setConfirmationText] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    const [successMessage, setSuccessMessage] = useState('');

    const token = sessionStorage.getItem("jwtToken");

    const copyEventAPI = useAPI({
        url: 'events/copy',
        method: 'POST',
        auth: true,
        token: token ? token : "",
    });

    const handleCopyEvent = () => {
        if (confirmationText !== 'rozumiem') {
            setErrorMessage('Aby potwierdzić operację, wpisz "rozumiem"');
            return;
        }

        setErrorMessage('');
        setSuccessMessage('');
        const formattedDate = format(new Date(eventDate), 'yyyy-MM-dd HH:mm');
        copyEventAPI
            .call({
                body: {
                    eventId,
                    eventDate: formattedDate,
                    copyElements,
                },
            })
            .then((res) => {
                if (res?.response.ok) {
                    setSuccessMessage('Wydarzenie zostało pomyślnie skopiowane');
                    onCopy();
                } else if (res?.data?.error) {
                    switch (res.data.error) {
                        case 'missing parameters':
                            setErrorMessage('Brakujące parametry.');
                            break;
                        case 'Invalid or past date':
                            setErrorMessage('Nieprawidłowa lub przeszła data.');
                            break;
                        default:
                            setErrorMessage('Nieznany błąd.');
                            break;
                    }
                }
            })
            .catch(() => {
                setErrorMessage('Wystąpił problem z kopiowaniem wydarzenia.');
            });
    };

    return (
        <div className="copy-event-modal">
            <div className="copy-event-content">
                <h2>Kopiuj Wydarzenie</h2>
                <div className="form-group">
                    <label>Wybierz nową datę wydarzenia:</label>
                    <input
                        type="datetime-local"
                        value={eventDate}
                        onChange={(e) => setEventDate(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Czy chcesz skopiować elementy wydarzenia?</label>
                    <input
                        type="checkbox"
                        checked={copyElements}
                        onChange={(e) => setCopyElements(e.target.checked)}
                    />
                </div>
                <div className="form-group">
                    <label>Skopiowanego wydarzenia nie można usunąć. Możesz je tylko edytować.<br />
                        Aby potwierdzić operację, wpisz "rozumiem":</label>
                    <input
                        type="text"
                        value={confirmationText}
                        onChange={(e) => setConfirmationText(e.target.value)}
                    />
                </div>
                {errorMessage && <div className="error-message">{errorMessage}</div>}
                {successMessage && <div className="success-message">{successMessage}</div>}
                <div className="form-actions">
                    <button onClick={handleCopyEvent} className="submit-button">Kopiuj</button>
                    <button onClick={onClose} className="cancel-button">Anuluj</button>
                </div>
            </div>
        </div>
    );
};

export default CopyEvent;
