// src/components/Notifications/Notifications.tsx
import React, { useState, FormEvent, ChangeEvent } from 'react';
import useAPI from '../../hooks/UseAPI';
import MainPhoto from '../MainPhoto/MainPhoto';

interface FormData {
    email: string;
    name: string;
    surname: string;
}

interface Message {
    type: 'success' | 'error' | '';
    content: string;
}

export const Notifications = () => {
    const [formData, setFormData] = useState<FormData>({
        email: '',
        name: '',
        surname: ''
    });
    const [message, setMessage] = useState<Message>({ type: '', content: '' });

    const addEmailNotification = useAPI({
        url: 'email/enableemailnotification',
        method: 'POST',
    });

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        try {
            const result = await addEmailNotification.call({
                body: formData
            });

            if (result?.response?.ok) {
                setMessage({
                    type: 'success',
                    content: 'Pomyślnie zapisano do systemu powiadomień!'
                });
                setFormData({ email: '', name: '', surname: '' });
            } else {
                let errorMessage = 'Wystąpił błąd podczas zapisywania.';

                // The error message is directly in the response
                if (result?.message === 'already registered') {
                    errorMessage = 'Ten adres email jest już zarejestrowany w systemie powiadomień.';
                } else if (result?.error === 'Name and surname must be less than 50 characters') {
                    errorMessage = 'Niepoprawnę Imię lub Nazwisko';
                } else if (result?.message === 'email is not valid') {
                    errorMessage = 'Podany adres email jest nieprawidłowy.';
                } else if (result?.status === 'Missing parameters') {
                    errorMessage = 'Wszystkie pola są wymagane.';
                }

                setMessage({
                    type: 'error',
                    content: errorMessage
                });
            }
        } catch (error) {
            console.log('Catch error:', error);
            setMessage({
                type: 'error',
                content: 'Wystąpił błąd podczas połączenia z serwerem.'
            });
        }
    };
    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setFormData(prev => ({
            ...prev,
            [name]: value
        }));
    };

    return (
        <>
            <MainPhoto />
            <div className="registrationCheckContainer">
                <div className="registrationCheckContent">
                    <h2>Zapisz się do systemu powiadomień o wydarzenich UMG</h2>

                    <form onSubmit={handleSubmit}>
                        <input
                            type="email"
                            className="emailInput"
                            placeholder="Adres email"
                            name="email"
                            value={formData.email}
                            onChange={handleInputChange}
                        />

                        <input
                            type="text"
                            className="emailInput"
                            placeholder="Imię"
                            name="name"
                            value={formData.name}
                            onChange={handleInputChange}
                        />

                        <input
                            type="text"
                            className="emailInput"
                            placeholder="Nazwisko"
                            name="surname"
                            value={formData.surname}
                            onChange={handleInputChange}
                        />

                        <button
                            type="submit"
                            className="checkButton"
                            disabled={addEmailNotification.loading}
                        >
                            {addEmailNotification.loading ? 'Zapisywanie...' : 'Zapisz się'}
                        </button>
                    </form>

                    {message.content && (
                        <div className={`message ${message.type === 'success' ? 'confirmed' : 'notConfirmed'}`}>
                            {message.content}
                        </div>
                    )}

                    <div className="registrationInfo">
                        <h3>Informacje o systemie powiadomień</h3>
                        <p>
                            Zapisując się do systemu powiadomień, będziesz otrzymywać powiadomienia o wybranych wydarzeniach
                            organizowanych przez Uniwersytet Morski w Gdyni.
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};