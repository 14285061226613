import { useEffect, useMemo, useState } from "react";
import useAPI from "../../hooks/UseAPI";
import {
    EventElementContainer,
    EventElementProps,
} from "./EventElementContainer";
import umg from "../../assets/UMGLogo.png";
import we from "../../assets/WELogo.png";
import wm from "../../assets/WMLogo.png";
import wn from "../../assets/WNLogo.png";
import wi from "../../assets/WILogo.png";
import wznj from "../../assets/WZNJLogo.png";
import "./event.style.css";
import { DepartmentType, DepartmentsContainer } from "./DepartmentsContainer";
import { CreateEventElement } from "./CreateEventElement";

const departmentList: DepartmentType[] = [
    {
        id: 5,
        name: "Wydarzenia Ogólne",
        image: umg,
    },
    {
        id: 4,
        name: "Wydział Elektryczny",
        image: we,
    },
    {
        id: 6,
        name: "Wydział Informatyki",
        image: wi,
    },
    {
        id: 2,
        name: "Wydział Mechaniczny",
        image: wm,
    },
    {
        id: 1,
        name: "Wydział Nawigacyjny",
        image: wn,
    },
    {
        id: 3,
        name: "Wydział Zarządzania I Nauk o Jakości",
        image: wznj,
    },
    {
        id: 0,
        name: "Wszystkie Wydziały",
    },
];

type EventElementsListProps = {
    isAdminOptions: boolean;
    showWarning: (message: string) => void;
    eventId: number;
};

export const EventElementsList = (props: EventElementsListProps) => {
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [faculty, setFaculty] = useState<number>(0);
    const [sortBy, setSortBy] = useState<number>(0);
    const [filter, setFilter] = useState<number>(0);
    const [elements, setElements] = useState<EventElementProps[] | undefined>(undefined);

    const eventElements = useAPI({
        url: "eventelement/getpagedelements",
        method: "POST",
    });

    useEffect(() => {
        eventElements
            .call({
                body: {
                    eventId: props.eventId,
                    sortBy: sortBy,
                    pageNumber: 1,
                    elementsPerPage: props.isAdminOptions ? 7 : 8,
                    faculty: faculty,
                    onlyActive: !props.isAdminOptions,
                    filter: filter,
                },
            })
            .then((res) => {
                if (res?.response?.ok) {
                    const newElements = res.data.data.map((element: EventElementProps) => ({
                        ...element,
                        elementVisibility: element.elementVisibility
                    }));
                    setElements(newElements);
                }
            });
    }, [props.isAdminOptions, sortBy, faculty, filter, props.eventId]);

    const filterPages = (
        pageNumber: number,
        sortedBy: number | string,
        facultyBy: number,
        filter: number | string
    ) => {
        const sortedByNum = typeof sortedBy === "string" ? parseInt(sortedBy) : sortedBy;
        const filterNum = typeof filter === "string" ? parseInt(filter) : filter;

        eventElements
            .call({
                body: {
                    eventId: props.eventId,
                    sortBy: sortedByNum,
                    pageNumber: pageNumber,
                    elementsPerPage: props.isAdminOptions ? 7 : 8,
                    faculty: facultyBy,
                    onlyActive: !props.isAdminOptions,
                    filter: filterNum,
                },
            })
            .then((res) => {
                if (res?.response?.ok) {
                    setElements(res.data.data);
                    setCurrentPage(pageNumber);
                }
            });
    };

    const updateElementVisibility = (id: number, visibility: boolean) => {
        setElements(prevElements =>
            prevElements?.map(element =>
                element.id === id ? {...element, elementVisibility: visibility} : element
            ) ?? []
        );
    };

    const updateElementImportance = (id: number, importance: boolean) => {
        setElements(prevElements =>
            prevElements?.map(element =>
                element.id === id ? {...element, important: importance} : element
            ) ?? []
        );
    };

    const pages = () => {
        const buttons = [];
        for (let i = 1; i <= eventElements.data.totalPages; i++) {
            buttons.push(
                <button
                    key={i}
                    onClick={() => {
                        filterPages(i, sortBy, faculty, filter);
                    }}
                    style={{
                        borderBottom: currentPage === i ? "2px solid rgba(45, 71, 132, 1)" : "none",
                        color: currentPage === i ? "#000" : "rgba(45, 71, 132, 1)",
                    }}
                >
                    {i}
                </button>
            );
        }

        if (eventElements.data.data.length !== 0 && eventElements.data.currentPage !== eventElements.data.totalPages) {
            buttons.push(
                <button
                    key="next"
                    style={{ color: "rgba(100, 153, 202, 1)" }}
                    onClick={() => {
                        filterPages(currentPage + 1, sortBy, faculty, filter);
                    }}
                >
                    Następna
                </button>
            );
        }

        if (eventElements.data.data.length !== 0 && eventElements.data.currentPage !== 1) {
            buttons.unshift(
                <button
                    key="prev"
                    style={{ color: "rgba(100, 153, 202, 1)" }}
                    onClick={() => {
                        filterPages(currentPage - 1, sortBy, faculty, filter);
                    }}
                >
                    Poprzednia
                </button>
            );
        }

        return buttons;
    };

    const renderEventElements = useMemo(() => {
        if (elements && elements.length > 0) {
            return elements.map((event: EventElementProps) => (
                <EventElementContainer
                    key={event.id}
                    {...event}
                    {...props}
                    elements={elements}
                    setElements={setElements}
                    eventElementsCall={eventElements}
                    sortBy={sortBy}
                    filter={filter}
                    faculty={faculty}
                    isAdminOptions={props.isAdminOptions}
                    updateElementVisibility={updateElementVisibility}
                    updateElementImportance={updateElementImportance}
                    showWarning={props.showWarning}
                />
            ));
        }
        return null;
    }, [elements, props, eventElements, sortBy, filter, faculty]);

    const departmentsOptions = useMemo(() => {
        return (
            <div className="departmentsFilter">
                <DepartmentsContainer
                    departmentList={departmentList}
                    sortBy={sortBy}
                    filterPages={filterPages}
                    faculty={faculty}
                    setFaculty={setFaculty}
                    filter={filter}
                />
            </div>
        );
    }, [sortBy, filter, faculty]);

    return (
        <>
            <div className="sortEventElements">
                <h3 style={{ marginTop: "15px" }}>Sortowanie</h3>
                <select
                    onChange={(event) => {
                        setSortBy(parseInt(event.target.value));
                        filterPages(1, event.target.value, faculty, filter);
                    }}
                >
                    <option value="0">Od najnowszych do najstarszych</option>
                    <option value="1">Od najstarszych do najnowszych</option>
                    <option value="3">Od największej liczby wolnych miejsc</option>
                    <option value="2">Od najmniejszej liczby wolnych miejsc</option>
                </select>
            </div>

            <div className="sortEventElements">
                <h3 style={{ marginTop: "15px" }}>Rodzaje wydarzeń</h3>
                <select
                    onChange={(event) => {
                        setFilter(parseInt(event.target.value));
                        filterPages(1, sortBy, faculty, event.target.value);
                    }}
                >
                    <option value="0">Wszystkie</option>
                    <option value="1">Wykłady</option>
                    <option value="2">Ćwiczenia</option>
                    <option value="3">Warsztaty</option>
                    <option value="4">Prezentacje</option>
                    <option value="5">Zwiedzanie Uczelni</option>
                </select>
            </div>

            {departmentsOptions}

            <div className="eventElementsList">
                {props.isAdminOptions && (
                    <CreateEventElement
                        elements={elements || []}
                        eventElementsCall={eventElements}
                        sortBy={sortBy}
                        filter={filter}
                        faculty={faculty}
                        isAdminOptions={props.isAdminOptions}
                        setElements={setElements}
                        eventId={props.eventId}
                    />
                )}
                {renderEventElements}
            </div>
            {eventElements.data?.data && (
                <div className="nextPages">{elements && pages()}</div>
            )}
        </>
    );
};