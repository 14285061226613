import React, { useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';
import './popUpNewPass.style.css';
import eyeIcon from '../../../../assets/EyeIcon.png';
import useAPI from "../../../../hooks/UseAPI";

export const PopUpNewPass = () => {
    const [isPasswordShown, setIsPasswordShown] = useState(false);
    const [isConfirmPasswordShown, setIsConfirmPasswordShown] = useState(false);
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [error, setError] = useState('');
    const navigate = useNavigate();
    const { token } = useParams();

    const newPass = useAPI({
        url: "user/passwordreset",
        method: "POST",
    })

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        if (newPassword !== confirmNewPassword) {
            setError("Musisz wprowadzić to samo hasło w dwóch polach");
            return;
        }
        newPass.call({
            body: {
                password: newPassword,
                confirmPassword: confirmNewPassword,
                validationToken: token,
            }
        })
            .then((res) => {
                if (res?.response !== undefined && res?.response.ok) {
                    navigate('/paneladmin/login');
                } else {
                    setError("Hasło musi zawierać co najmniej 1 dużą literę, 1 małą literę, cyfrę, znak specjalny. Minimalna długość to 8 znaków!");
                }
            })
    };

    return (
        <div className="popup-content-reset">
            <div className="newPassForm">
                <form onSubmit={handleSubmit}>
                    <h2>Nowe Hasło</h2>
                    {error && <div className="password-error">{error}</div>}
                    <div className="input-box">
                        <label>Nowe Hasło</label>
                        <input
                            type={isPasswordShown ? "Viewpassword" : "password"}
                            placeholder="*****"
                            required
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                        />
                        <button type="button" className="toggle-password" onClick={() => setIsPasswordShown(!isPasswordShown)}>
                            <img src={eyeIcon} alt="Toggle Password Visibility" />
                        </button>
                    </div>
                    <div className="input-box">
                        <label>Powtórz nowe hasło</label>
                        <input
                            type={isConfirmPasswordShown ? "Viewpassword" : "password"}
                            placeholder="*****"
                            required
                            value={confirmNewPassword}
                            onChange={(e) => setConfirmNewPassword(e.target.value)}
                        />
                        <button type="button" className="toggle-password" onClick={() => setIsConfirmPasswordShown(!isConfirmPasswordShown)}>
                            <img src={eyeIcon} alt="Toggle Password Visibility" />
                        </button>
                    </div>

                    <button type="submit" className="btn">Zapisz</button>
                </form>
            </div>
        </div>
    )
}
