import React from "react";
import "../Modal/modal.style.css";

interface ModalProps {
    children: React.ReactNode;
}

export const Modal = ({ children }: ModalProps) => {
    return (
        <div className="modalBackground">
            {children}
        </div>

    );
};