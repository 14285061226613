import React, { useState } from "react";
import eyeIcon from '../../../../assets/EyeIcon.png';
import './popUpLogin.style.css';
import { useNavigate } from 'react-router-dom';
import useAPI from "../../../../hooks/UseAPI";

interface PopUpLoginProps {
    onForgotPassword: () => void;
}

export const PopUpLogin = ({ onForgotPassword }: PopUpLoginProps) => {
    const [isPasswdShown, setIsPasswdShown] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [blocked, setBlocked] = useState(false);
    const navigate = useNavigate();
    const loginAdmin = useAPI({
        url:"user/login",
        method: 'POST',
    });

    const handleLoginSuccess = (e: React.FormEvent) => {
        setError("");
        e.preventDefault();
        loginAdmin.call({
            body:{
                username: email,
                password: password,
                
            }
        })
        .then((res)=> {
            if(res !== undefined && res.response !== undefined && res?.response.ok){
                const token = res.data.token;
                sessionStorage.setItem('jwtToken',token);
                navigate('/');
            }else{
                if(res.status === 'Invalid credentials'){
                    setError("Błędny E-mail lub hasło");
                }
            }
        })
    };

    return (
        <div className="popup-content-login">
            <div className="loginForm">
                <form onSubmit={handleLoginSuccess}>
                    <h2>Logowanie</h2>
                    {error && <div className="login-error">{error}</div>}
                    <div className="input-box">
                        <input type="text" placeholder="E-mail" required value={email} onChange={(e) => setEmail(e.target.value)} />
                    </div>
                    <div className="input-box">
                        <input type={isPasswdShown ? "Viewpassword" : "password"} placeholder="Hasło" required value={password} onChange={(e) => setPassword(e.target.value)} />
                        <button type="button" className="toggle-password" onClick={() => setIsPasswdShown(!isPasswdShown)}>
                            <img src={eyeIcon} alt="Toggle Password Visibility" />
                        </button>
                    </div>
                    <div className="forgotpasswd">
                        <label>
                            <a onClick={onForgotPassword}>Zapomniałem hasła</a>
                        </label>
                    </div>
                    <button type="submit" className="btn">Zaloguj się</button>
                </form>
            </div>
        </div>
    );
};