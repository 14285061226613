import React, { useState, useEffect, useCallback } from "react";
import "../MainPhoto/mainPhoto.style.css";
import { SmallModal } from "../Modal/SmalModal";
import { VscClose } from "react-icons/vsc";
import useAPI from '../../hooks/UseAPI';
import defaultBackgroundImage from '../../assets/Bg_umg.jpg';
import BackgroundImageManager from './BackgroundImageManager';

type MainPhotoProps = {
  upperPopUpText?: string;
  lowerPopUpText?: string;
  showAdminOptions?: boolean;
  token?: string | null;
};

const MainPhoto: React.FC<MainPhotoProps> = (props) => {
  const [visiblePopup, setVisiblePopup] = useState(true);
  const [backgroundImage, setBackgroundImage] = useState<string | null>(null);
  const [showImageManager, setShowImageManager] = useState(false);
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [previewImageId, setPreviewImageId] = useState<number | null>(null);

  const getBackgroundImageAPI = useAPI({
    url: "image/getimage/null",
    method: "GET",
    auth: true,
    token: props.token ?? undefined,
  });

  const setImageAPI = useAPI({
    url: "image/setimage",
    method: "POST",
    auth: true,
    token: props.token ?? undefined,
  });

  const scrollToTop = useCallback(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    getBackgroundImageAPI.call();
  }, []);

  useEffect(() => {
    if (getBackgroundImageAPI.data && getBackgroundImageAPI.data.base64Content) {
      setBackgroundImage(`url(data:image/jpeg;base64,${getBackgroundImageAPI.data.base64Content})`);
    } else if (getBackgroundImageAPI.error) {
      setBackgroundImage(`url(${defaultBackgroundImage})`);
    }
    scrollToTop();
  }, [getBackgroundImageAPI.data, getBackgroundImageAPI.error, scrollToTop]);

  const handlePreview = (image: { id: number; base64Content: string }) => {
    setPreviewImageId(image.id);
    setPreviewImage(`url(data:image/jpeg;base64,${image.base64Content})`);
    setShowImageManager(false);
    scrollToTop();
  };

  const handleSave = () => {
    if (previewImageId !== null) {
      setImageAPI.call({ body: { imageId: previewImageId } }).then((res) => {
        if (res?.response && res.response.ok) {
          setBackgroundImage(previewImage!);
          setPreviewImage(null);
          setPreviewImageId(null);
          getBackgroundImageAPI.call();
        }
      });
    }
  };

  const handleCancel = () => {
    setPreviewImage(null);
    setPreviewImageId(null);
    scrollToTop();
  };

  return (
      <div className="main-photo-container">
        {backgroundImage && (
            <div
                className="fullscreen-background"
                style={{ backgroundImage: previewImage || backgroundImage }}
            >
              {props.lowerPopUpText && props.upperPopUpText && visiblePopup && !previewImage && (
                  <SmallModal>
                    <button className="closeButton" onClick={() => setVisiblePopup(false)}>
                      <VscClose style={{ width: "40px", height: "40px" }} />
                    </button>
                    <div>
                      <h2>{props.upperPopUpText}</h2>
                      <br />
                      <p>{props.lowerPopUpText}</p>
                    </div>
                  </SmallModal>
              )}
              {props.showAdminOptions && !previewImage && (
                  <button
                      className="change-background-button"
                      onClick={() => setShowImageManager(true)}
                  >
                    Zmień zdjęcie w tle
                  </button>
              )}
              {props.showAdminOptions && previewImage && (
                  <div className="preview-buttons">
                    <button onClick={handleSave}>Zapisz</button>
                    <button onClick={handleCancel}>Anuluj</button>
                  </div>
              )}
            </div>
        )}
        {showImageManager && (
            <BackgroundImageManager
                showAdminOptions={props.showAdminOptions ?? false}
                token={props.token}
                onClose={() => setShowImageManager(false)}
                onPreview={handlePreview}
            />
        )}
      </div>
  );
};

export default MainPhoto;