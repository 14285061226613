import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./userRegistration.style.css";
import MainPhoto from "../MainPhoto/MainPhoto";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faXmark } from '@fortawesome/free-solid-svg-icons';

interface RegistrationData {
    status: {
        email: string;
    };
    events: {
        [key: string]: {
            eventId: number;
            eventName: string;
            eventDate: string;
            registrations: {
                confirmed: boolean;
                participants: number;
                elementName: string;
                elementDescription: string;
                elementDateStart: string;
                elementDateEnd: string;
                elementFaculty: number;
                elementAddress: string;
                confirmationToken?: string;
            }[];
        };
    };
}

export const UserRegistrationInfo: React.FC = () => {
    const location = useLocation();
    const [registrationData, setRegistrationData] = useState<RegistrationData | null>(null);
    const [error, setError] = useState<string | null>(null);
    const [expandedEvents, setExpandedEvents] = useState<{ [key: string]: boolean }>({});
    const [expandedRegistrations, setExpandedRegistrations] = useState<{ [key: string]: boolean }>({});
    const [confirmingRegistrations, setConfirmingRegistrations] = useState<{ [key: string]: boolean }>({});

    useEffect(() => {
        const fetchUserRegistrations = async () => {
            const token = location.pathname.split('/registrationinfo/')[1];
            try {
                const response = await fetch(`${process.env.REACT_APP_API_URL}userregistration/validaterequesttoken/${token}`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                if (response.ok) {
                    const data: RegistrationData = await response.json();
                    setRegistrationData(data);
                } else {
                    setError("Nieprawidłowy token lub brak informacji o rejestracjach.");
                }
            } catch (error) {
                setError("Wystąpił błąd podczas pobierania informacji o rejestracjach.");
            }
        };

        fetchUserRegistrations();
    }, [location]);

    const confirmRegistration = async (confirmationToken: string, eventId: number, registrationIndex: number) => {
        if (!confirmationToken) return;

        setConfirmingRegistrations(prev => ({ ...prev, [`${eventId}-${registrationIndex}`]: true }));

        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}userregistration/confirm/${confirmationToken}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (response.ok) {
                // Update the local state to reflect the confirmation
                setRegistrationData(prevData => {
                    if (!prevData) return null;

                    const newData = { ...prevData };
                    newData.events[eventId].registrations[registrationIndex].confirmed = true;
                    return newData;
                });
            } else {
                setError("Nie udało się potwierdzić rejestracji.");
            }
        } catch (error) {
            setError("Wystąpił błąd podczas potwierdzania rejestracji.");
        } finally {
            setConfirmingRegistrations(prev => ({ ...prev, [`${eventId}-${registrationIndex}`]: false }));
        }
    };

    const toggleEvent = (eventId: number) => {
        setExpandedEvents(prev => ({ ...prev, [eventId]: !prev[eventId] }));
    };

    const toggleRegistration = (registrationId: string) => {
        setExpandedRegistrations(prev => ({ ...prev, [registrationId]: !prev[registrationId] }));
    };

    const isPastEvent = (eventDate: string) => {
        const eventStartDate = new Date(eventDate);
        const currentDate = new Date();
        return eventStartDate < currentDate;
    };

    const upcomingEvents = registrationData
        ? Object.entries(registrationData.events).filter(([_,eventInfo]) => !isPastEvent(eventInfo.eventDate))
        : [];

    const pastEvents = registrationData
        ? Object.entries(registrationData.events).filter(([_,eventInfo]) => isPastEvent(eventInfo.eventDate))
        : [];

    const renderRegistrationStatus = (reg: any, eventId: number, index: number, isPast: boolean) => {
        if (reg.confirmed) {
            return (
                <div className="registrationStatus confirmed">
                    Potwierdzono <FontAwesomeIcon icon={faCheck} />
                </div>
            );
        }

        if (isPast) {
            return (
                <div className="registrationStatus unconfirmed">
                    Niepotwierdzono <FontAwesomeIcon icon={faXmark} />
                </div>
            );
        }

        return (
            <div className="registrationStatus unconfirmed">
                <div>
                    Niepotwierdzono <FontAwesomeIcon icon={faXmark} />
                </div>
                {reg.confirmationToken && (
                    <button
                        onClick={(e) => {
                            e.stopPropagation();
                            confirmRegistration(reg.confirmationToken, eventId, index);
                        }}
                        className="confirmButton"
                        disabled={confirmingRegistrations[`${eventId}-${index}`]}
                    >
                        {confirmingRegistrations[`${eventId}-${index}`] ? 'Potwierdzanie...' : 'Potwierdź udział'}
                    </button>
                )}
            </div>
        );
    };

    return (
        <>
            <MainPhoto />
            <div className="registrationCheckContainer">
                <div className="registrationCheckContent">
                    <h2>Twoje rejestracje</h2>
                    {error ? (
                        <div className="errorMessage">{error}</div>
                    ) : registrationData ? (
                        <div className="registrationInfo">
                            <p><strong>E-mail:</strong> {registrationData.status.email}</p>
                            <h3>Nadchodzące wydarzenia</h3>
                            {upcomingEvents.length > 0 ? (
                                upcomingEvents.map(([eventId, eventInfo]) => (
                                    <div key={eventId} className="eventItem">
                                        <button onClick={() => toggleEvent(Number(eventId))} className="eventToggle">
                                            {eventInfo.eventName} - {eventInfo.eventDate}
                                        </button>
                                        {expandedEvents[eventId] && (
                                            <div className="registrationsList">
                                                {eventInfo.registrations.map((reg, index) => (
                                                    <div key={index} className="registrationItem">
                                                        <button
                                                            onClick={() => toggleRegistration(`${eventId}-${index}`)}
                                                            className={`registrationToggle ${expandedRegistrations[`${eventId}-${index}`] ? 'registrationToggleActive' : ''}`}
                                                        >
                                                            <div className="registrationDetailsContainer">
                                                                <div className="registrationText">
                                                                    <p className="elementName">{reg.elementName}</p>
                                                                    <p className="participants">Uczestnicy: {reg.participants}</p>
                                                                </div>
                                                                {renderRegistrationStatus(reg, Number(eventId), index, false)}
                                                            </div>
                                                        </button>
                                                        {expandedRegistrations[`${eventId}-${index}`] && (
                                                            <div className="registrationDetails">
                                                                <p><strong>Opis:</strong> {reg.elementDescription}</p>
                                                                <p><strong>Data rozpoczęcia:</strong> {reg.elementDateStart}</p>
                                                                <p><strong>Data zakończenia:</strong> {reg.elementDateEnd}</p>
                                                                <p><strong>Wydział:</strong> {reg.elementFaculty}</p>
                                                                <p><strong>Adres:</strong> {reg.elementAddress}</p>
                                                            </div>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                ))
                            ) : (
                                <div className="eventItem">
                                    <div className="eventToggleEmpty">Brak nadchodzących wydarzeń</div>
                                </div>
                            )}
                            <h3>Zakończone wydarzenia</h3>
                            {pastEvents.length > 0 ? (
                                pastEvents.map(([eventId, eventInfo]) => (
                                    <div key={eventId} className="eventItem">
                                        <button onClick={() => toggleEvent(Number(eventId))} className="eventToggle">
                                            {eventInfo.eventName} - {eventInfo.eventDate}
                                        </button>
                                        {expandedEvents[eventId] && (
                                            <div className="registrationsList">
                                                {eventInfo.registrations.map((reg, index) => (
                                                    <div key={index} className="registrationItem">
                                                        <button
                                                            onClick={() => toggleRegistration(`${eventId}-${index}`)}
                                                            className={`registrationToggle ${expandedRegistrations[`${eventId}-${index}`] ? 'registrationToggleActive' : ''}`}
                                                        >
                                                            <div className="registrationDetailsContainer">
                                                                <div className="registrationText">
                                                                    <p className="elementName">{reg.elementName}</p>
                                                                    <p className="participants">Uczestnicy: {reg.participants}</p>
                                                                </div>
                                                                {renderRegistrationStatus(reg, Number(eventId), index, true)}
                                                            </div>
                                                        </button>
                                                        {expandedRegistrations[`${eventId}-${index}`] && (
                                                            <div className="registrationDetails">
                                                                <p><strong>Opis:</strong> {reg.elementDescription}</p>
                                                                <p><strong>Data rozpoczęcia:</strong> {reg.elementDateStart}</p>
                                                                <p><strong>Data zakończenia:</strong> {reg.elementDateEnd}</p>
                                                                <p><strong>Wydział:</strong> {reg.elementFaculty}</p>
                                                                <p><strong>Adres:</strong> {reg.elementAddress}</p>
                                                            </div>
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </div>
                                ))
                            ) : (
                                <p>Brak zakończonych wydarzeń.</p>
                            )}
                        </div>
                    ) : (
                        <div>Ładowanie informacji...</div>
                    )}
                </div>
            </div>
        </>
    );
};

export default UserRegistrationInfo;