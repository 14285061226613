import React, { useState } from "react";
import { Modal } from "../Modal/Modal";
import { RegistrationPopup } from "./RegistrationPopup";
import { FaRegEyeSlash, FaRegTrashAlt, FaRegEye, FaList, FaStar, FaRegStar } from "react-icons/fa";
import { IoBookOutline } from "react-icons/io5";
import { MdComputer } from "react-icons/md";
import { SlWrench } from "react-icons/sl";
import { GrWorkshop } from "react-icons/gr";
import { FaPersonWalking } from "react-icons/fa6";
import useAPI from "../../hooks/UseAPI";
import { EditElementPopup } from "./EditElementPopup";
import { DeleteElementPopup } from "./DeleteElementPopup";
import { ListElementPopup } from "./ListElementPopup";
import { EventElementPopup } from "./EventElementPopup";
import CopyEventElement from './CopyEventElement';
import "./event.style.css";

export type EventElementProps = {
  id: number;
  elementName: string;
  elementDescription: string;
  room: string;
  faculty: number;
  elementVisibility: boolean;
  participants: string | number;
  currentParticipants: number;
  maxParticipants: string | number;
  important: boolean;
  eventId: number;
  imageId: number;
  eventElementStartDateTime: string;
  eventElementEndDateTime: string;
  eventElementsCall: any;
  sortBy: number;
  filter: number;
  isAdminOptions: boolean;
  typeOfElement: string;
};

type EventElementContainerProps = {
  isAdminOptions: boolean;
  elements: EventElementProps[];
  setElements: (ele: EventElementProps[]) => void;
  updateElementVisibility: (id: number, visibility: boolean) => void;
  updateElementImportance: (id: number, importance: boolean) => void;
  showWarning: (message: string) => void;
  isImportant?: boolean;
} & EventElementProps;

export const EventElementContainer = (props: EventElementContainerProps) => {
  const [dateStartPart, timeStartPart] = props.eventElementStartDateTime.split("T");
  const [dateEndPart, timeEndPart] = props.eventElementEndDateTime.split("T");
  const [error, setError] = useState("");
  const [visiblePopup, setVisiblePopup] = useState<boolean>(false);
  const [visibleRegisPopup, setVisibleRegisPopup] = useState<boolean>(false);
  const [visibleEditPopup, setVisibleEditPopup] = useState<boolean>(false);
  const [visibleDeletePopup, setVisibleDeletePopup] = useState<boolean>(false);
  const [visibleListPopup, setVisibleListPopup] = useState<boolean>(false);
  const [visibleCopyPopup, setVisibleCopyPopup] = useState<boolean>(false);
  const token = sessionStorage.getItem("jwtToken");

  const elementVisibility = useAPI({
    url: "eventelement/setvisibility",
    method: "POST",
    auth: true,
    token: token ? token : "",
  });

  const deleteElement = useAPI({
    url: "eventelement/deleteelement",
    method: "POST",
    auth: true,
    token: token ? token : "",
  });

  const setImportantElement = useAPI({
    url: "eventelement/setimportantelement",
    method: "POST",
    auth: true,
    token: token ? token : "",
  });

  const visabilityElement = props.elementVisibility;

  const eventLogo = () => {
    switch (props.imageId) {
      case 1:
        return <IoBookOutline style={{width: "100px", height: "100px"}}/>;
      case 2:
        return <MdComputer style={{width: "100px", height: "100px"}}/>;
      case 3:
        return <SlWrench style={{width: "100px", height: "100px"}}/>;
      case 4:
        return <GrWorkshop style={{width: "100px", height: "100px"}}/>;
      case 5:
        return <FaPersonWalking style={{
          width: "100px",
          height: "100px",
          fill: "none",
          stroke: "while",
          strokeWidth: "20",
          overflow: "visible"
        }}/>;
    }
  };

  const truncateString = (str: string, maxLength: number) => {
    if (str.length <= maxLength) {
      return str;
    } else {
      const truncatedString = str.substring(0, maxLength);
      const lastSpaceIndex = truncatedString.lastIndexOf(" ");
      return truncatedString.substring(0, lastSpaceIndex) + "...";
    }
  };

  const truncatedName = truncateString(props.elementName, 80);

  const handleRegisterClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setVisibleRegisPopup(true);
  };

  const handleCopyClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setVisibleCopyPopup(true);
  };

  const handleEditClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setVisibleEditPopup(true);
  };

  const handleCopyEvent = () => {
    setVisibleCopyPopup(false);
  };

  const handleImportantClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    const newImportantStatus = !props.important;

    setImportantElement
        .call({
          body: {
            eventElementId: props.id,
            important: newImportantStatus,
          },
        })
        .then((res) => {
          if (res && res.response && res.response.ok) {
            props.updateElementImportance(props.id, newImportantStatus);
          } else if (res && res.status === "there is too much of important event elements") {
            props.showWarning("Za dużo ważnych wydarzeń. Usuń niektóre przed dodaniem nowych.");
          }
        })
        .catch((err) => {
          console.error("API call error:", err);
        });
  };

  const handleElementVisibilityClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    const newVisibility = !visabilityElement;
    elementVisibility
        .call({
          body: {
            eventElementId: props.id,
            visible: newVisibility,
          },
        })
        .then((res) => {
          if (res !== undefined && res.response !== undefined && res.response.ok) {
            props.updateElementVisibility(props.id, newVisibility);
          }
        });
  };

  const handleDeleteClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setVisibleDeletePopup(true);
  };

  const handleListClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setVisibleListPopup(true);
  };

  const elementVison = () => {
    switch (visabilityElement) {
      case true:
        return <FaRegEye style={{width: "20px", height: "20px"}}/>;
      case false:
        return <FaRegEyeSlash style={{width: "20px", height: "20px"}}/>;
      default:
        return null;
    }
  };

  return (
      <>
        {visiblePopup && (
            <Modal>
              <EventElementPopup
                  {...props}
                  seatsLeft={props.participants}
                  dateStartPart={dateStartPart}
                  timeStartPart={timeStartPart}
                  timeEndPart={timeEndPart}
                  setVisiblePopup={() => setVisiblePopup(false)}
              />
            </Modal>
        )}
        {visibleRegisPopup && (
            <Modal>
              <RegistrationPopup
                  eventElementId={props.id}
                  eventElementName={props.elementName}
                  setVisiblePopup={() => setVisibleRegisPopup(false)}
              />
            </Modal>
        )}
        {visibleEditPopup && (
            <Modal>
              <EditElementPopup
                  eventElementId={props.id}
                  setVisiblePopup={() => setVisibleEditPopup(false)}
                  token={token || ""}
                  elements={props.elements}
                  eventElementsCall={props.eventElementsCall}
                  sortBy={props.sortBy}
                  filter={props.filter}
                  faculty={props.faculty}
                  isAdminOptions={props.isAdminOptions}
                  setElements={props.setElements}
                  eventId={props.eventId}
                  isImportant={props.isImportant || false}
              />
            </Modal>
        )}
        {visibleCopyPopup && (
            <Modal>
              <CopyEventElement
                  eventElementId={props.id}
                  eventElementName={props.elementName}
                  onClose={() => setVisibleCopyPopup(false)}
                  onCopy={handleCopyEvent}
                  eventId={props.eventId}
                  eventElementsCall={props.eventElementsCall}
                  setElements={props.setElements}
                  isAdminOptions={props.isAdminOptions}
                  sortBy={props.sortBy}
                  filter={props.filter}
                  faculty={props.faculty}
              />
            </Modal>
        )}
        {visibleDeletePopup && (
            <Modal>
              <DeleteElementPopup
                  eventElementId={props.id}
                  eventElementName={props.elementName}
                  dateStartPart={dateStartPart}
                  timeStartPart={timeStartPart}
                  timeEndPart={timeEndPart}
                  setVisiblePopup={() => setVisibleDeletePopup(false)}
                  elements={props.elements}
                  setElements={props.setElements}
                  token={token || ""}
                  deleteCall={deleteElement}
                  deleteBody={{
                    eventElementId: props.id,
                  }}
                  error={error}
                  setError={setError}
              />
            </Modal>
        )}
        {visibleListPopup && (
            <Modal>
              <ListElementPopup
                  eventElementName={props.elementName}
                  eventElementId={props.id}
                  dateStartPart={dateStartPart}
                  timeStartPart={timeStartPart}
                  timeEndPart={timeEndPart}
                  setVisiblePopup={() => setVisibleListPopup(false)}
                  elements={props.elements}
                  setElements={props.setElements}
              />
            </Modal>
        )}
        <div
            className={`eventElementContainer ${props.typeOfElement === "information" ? 'informationType' : ''}`}
            onClick={() => setVisiblePopup(true)}
        >
          {props.isAdminOptions && (
              <>
                <button
                    className="visionButton"
                    onClick={handleElementVisibilityClick}
                >
                  {elementVison()}
                </button>
                <button className="deleteButton" onClick={handleDeleteClick}>
                  <FaRegTrashAlt style={{width: "20px", height: "20px"}}/>
                </button>
                {props.typeOfElement !== "information" && (
                    <button className="listButton" onClick={handleListClick}>
                      <FaList style={{width: "20px", height: "20px"}}/>
                    </button>
                )}
                <button
                    className={`importantButton ${props.important ? 'active' : ''}`}
                    onClick={handleImportantClick}
                >
                  {props.important ? <FaStar/> : <FaRegStar/>}
                </button>
              </>
          )}
          <div className="leftSide">
            {eventLogo()}
            <div className="descript">
              <h5>{truncatedName}</h5>
            </div>
          </div>
          <div className={`rightSide ${props.typeOfElement === "information" ? 'centeredTime' : ''}`}>
            <div>
              <h3>{dateStartPart}</h3>
              <br/>
              <p>
                {timeStartPart.split("+")[0].substring(0, 5)} -{" "}
                {timeEndPart.split("+")[0].substring(0, 5)}
              </p>
              {props.typeOfElement !== "information" && (
                  <>
                    <p>Miejsc zostało: {props.participants}</p>
                    <br/>
                    {props.isAdminOptions && (
                        <>
                          <p>Zapisanych osób: {props.currentParticipants}</p>
                          <br/>
                        </>
                    )}
                    {props.isAdminOptions ? (
                        <>
                          <button className="buttonEvent" onClick={handleEditClick}>
                            <b>Edytuj</b>
                          </button>
                          <button className="buttonEvent" onClick={handleCopyClick}>
                            <b>Kopiuj</b>
                          </button>
                        </>
                    ) : (
                        <>
                          {props.participants !== '0' ? (
                              <button className="buttonEvent" onClick={handleRegisterClick}>
                                <b>Zapisz się</b>
                              </button>
                          ) : (
                              <button className="buttonEvent">
                                <b>brak miejsc</b>
                              </button>
                          )}
                        </>
                    )}
                  </>
              )}
              {props.typeOfElement === "information" && props.isAdminOptions && (
                  <>
                    <button className="buttonEvent" onClick={handleEditClick}>
                      <b>Edytuj</b>
                    </button>
                    <button className="buttonEvent" onClick={handleCopyClick}>
                      <b>Kopiuj</b>
                    </button>
                  </>
              )}
            </div>
          </div>
          {!props.isAdminOptions && (
              <div className="bottomText">
                <p>Kliknij, aby dowiedzieć się więcej!</p>
              </div>
          )}
        </div>
      </>
  );
};