import React, { useState, useEffect, useRef } from 'react';
import useAPI from '../../hooks/UseAPI';
import { FaTrash, FaEye, FaUpload, FaTimes } from 'react-icons/fa';

type BackgroundImageManagerProps = {
    showAdminOptions: boolean;
    token?: string | null
    onClose: () => void;
    onPreview: (image: { id: number; base64Content: string }) => void;
};

const BackgroundImageManager: React.FC<BackgroundImageManagerProps> = ({
                                                                           showAdminOptions,
                                                                           token,
                                                                           onClose,
                                                                           onPreview,
                                                                       }) => {
    const [images, setImages] = useState([]);
    const [showUploadModal, setShowUploadModal] = useState(false);
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const managerRef = useRef<HTMLDivElement>(null);

    const getAllImagesAPI = useAPI({
        url: 'image/getallimages',
        method: 'GET',
        auth: true,
        token: token ?? undefined,
    });

    const removeImageAPI = useAPI({
        url: 'image/removeimage',
        method: 'POST',
        auth: true,
        token: token ?? undefined,
    });

    useEffect(() => {
        if (showAdminOptions) {
            getAllImagesAPI.call();
        }
    }, [showAdminOptions]);

    useEffect(() => {
        if (getAllImagesAPI.data) {
            setImages(getAllImagesAPI.data.images);
        }
    }, [getAllImagesAPI.data]);

    const handleRemove = (imageId: number) => {
        removeImageAPI.call({ body: { imageId } }).then(() => {
            getAllImagesAPI.call();
        });
    };

    const handleUpload = async () => {
        if (selectedFile) {
            const formData = new FormData();
            formData.append('file', selectedFile);

            try {
                const response = await fetch('https://zapisy.umg.edu.pl/api/image/uploadimage', {
                    method: 'POST',
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                    body: formData,
                });

                if (!response.ok) {
                    throw new Error('File upload failed');
                }

                getAllImagesAPI.call();
                setShowUploadModal(false);
                setSelectedFile(null);
            } catch (error) {
                console.error('Upload error:', error);
            }
        }
    };

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (managerRef.current && !managerRef.current.contains(event.target as Node)) {
                onClose();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [onClose]);

    return (
        <div className="background-image-manager" ref={managerRef}>
            <button className="add-image-button" onClick={() => setShowUploadModal(true)}>
                <FaUpload /> Dodaj nowe zdjęcie
            </button>
            <div className="image-grid">
                {images.map((image: any) => (
                    <div key={image.id} className="image-item">
                        <img
                            src={`data:image/jpeg;base64,${image.base64Content}`}
                            alt={image.filename}
                        />
                        <button onClick={() => onPreview(image)}>
                            <FaEye /> Podgląd
                        </button>
                        <button onClick={() => handleRemove(image.id)}>
                            <FaTrash />
                        </button>
                    </div>
                ))}
            </div>
            {showUploadModal && (
                <div className="upload-modal-overlay">
                    <div className="upload-modal">
                        <h2>Prześlij nowe zdjęcie</h2>
                        <div className="file-input-wrapper">
                            <input
                                type="file"
                                id="file-upload"
                                onChange={(e) => setSelectedFile(e.target.files?.[0] || null)}
                                accept="image/*"
                            />
                            <label htmlFor="file-upload" className="file-input-label">
                                {selectedFile ? selectedFile.name : 'Wybierz plik'}
                            </label>
                        </div>
                        <div className="upload-modal-buttons">
                            <button className="upload-button" onClick={handleUpload} disabled={!selectedFile}>
                                <FaUpload /> Prześlij zdjęcie
                            </button>
                            <button className="close-button" onClick={() => setShowUploadModal(false)}>
                                <FaTimes /> Zamknij
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default BackgroundImageManager;