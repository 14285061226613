import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar/Navbar';
import { MainPage } from './components/MainPage/MainPage';
import { WelcomePage } from './components/WelcomePage/WelcomePage';
import { useEffect, useState } from 'react';
import MainPhoto from './components/MainPhoto/MainPhoto';
import { Modal } from './components/Modal/Modal';
import { AdminPanelLogin } from './components/AdminPanelLogin/AdminPanelLogin';
import { UserRegistration } from './components/UserRegistration/UserRegistrationConfirm';
import { PopUpLogin } from './components/Login/PopUpLogin/PopupLogin/PopUpLogin';
import { UserRegistrationCancel } from './components/UserRegistration/UserRegistrationCancel';
import { UserRegistrationStatus } from './components/UserRegistration/UserRegistrationStatus';
import { UserRegistrationToken } from "./components/UserRegistration/UserRegistrationToken";
import { UserRegistrationInfo } from "./components/UserRegistration/UserRegistrationInfo";
import { Notifications } from './components/Notifications/Notifications';
import { NotificationsDisable } from './components/Notifications/NotificationsDisable';
import Footer from './components/Footer/Footer';

const FooterWrapper = () => {
  const location = useLocation();

  const routesWithFooter = ['/', '/event'];

  const shouldShowFooter = routesWithFooter.some(route =>
      location.pathname === route || location.pathname.startsWith(`${route}/`)
  );

  return shouldShowFooter ? <Footer /> : null;
};

function App() {
  const [isScrolled, setIsScrolled] = useState(false);
  const handleScroll = () => {
    if (window.scrollY > 0) {
      setIsScrolled(true);
    } else {
      setIsScrolled(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
      <Router>
        <div className="Navbar">
          <Navbar isScrolled={isScrolled}/>
        </div>
        <Routes>
          <Route path="/" element={<WelcomePage />} />
          <Route path="/event/:eventId/:path" element={<MainPage />} />
          <Route path="/registrationinfo/:token" element={<UserRegistrationInfo />} />
          <Route path="/checkregistration" element={<UserRegistrationToken />} />
          <Route path="/paneladmin/login" element={<AdminPanelLogin />} />
          <Route path="/paneladmin/resetpasswd/:token" element={<AdminPanelLogin />} />
          <Route path="/userRegistrationConfirm/:token" element={<UserRegistration/>}/>
          <Route path="/userRegistrationCancel/:token" element={<UserRegistrationCancel/>}/>
          <Route path="/userRegistrationStatus/:token" element={<UserRegistrationStatus/>}/>
          <Route path="/notifications/disable/:token" element={<NotificationsDisable/>}/>
          <Route path="/notifications" element={<Notifications/>}/>
        </Routes>
        <FooterWrapper />
      </Router>
  );
}

export default App;