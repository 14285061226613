import React from 'react';
import './footer.style.css';
import logoUmg from '../../assets/logo_białe.png';

const Footer = () => {
    return (
        <footer className="footer">
            <div className="footer-container">
                <div className="footer-logo-section">
                    <img src={logoUmg} alt="UMG Logo" className="footer-logo-image"/>
                    <div className="footer-text">
                        <p>Uniwersytet Morski w Gdyni<br/>ul. Morska 81-87, 81-225 Gdynia</p>
                    </div>
                </div>

                <div className="contact-info">
                    <h3>Kontakt do Biura Promocji UMG</h3>
                    <a href="mailto:zapisy@umg.edu.pl">zapisy@umg.edu.pl</a>
                    <a href="tel:+48 58 5586 274">+48 58 5586 274</a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;