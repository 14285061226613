import React, { useState } from 'react';
import useAPI from '../../hooks/UseAPI';
import '../WelcomePage/copyEvent.style.css';
import { format } from 'date-fns';

interface CopyEventElementProps {
    eventElementId: number;
    eventElementName: string;
    onClose: () => void;
    onCopy: () => void;
    eventId: number;
    eventElementsCall: any;
    setElements: (elements: any[]) => void;
    isAdminOptions: boolean;
    sortBy: number;
    filter: number;
    faculty: number;
}

const CopyEventElement: React.FC<CopyEventElementProps> = ({
                                                               eventElementId,
                                                               eventElementName,
                                                               onClose,
                                                               onCopy,
                                                               eventId,
                                                               eventElementsCall,
                                                               setElements,
                                                               isAdminOptions,
                                                               sortBy,
                                                               filter,
                                                               faculty
                                                           }) => {
    const [startDate, setStartDate] = useState<string>('');
    const [endDate, setEndDate] = useState<string>('');
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [successMessage, setSuccessMessage] = useState<string>('');

    const token = sessionStorage.getItem("jwtToken");

    const copyEventAPI = useAPI({
        url: 'eventelement/copy',
        method: 'POST',
        auth: true,
        token: token ? token : "",
    });

    const handleCopyEvent = () => {
        setErrorMessage('');
        setSuccessMessage('');

        if (!startDate || !endDate) {
            setErrorMessage('Proszę wprowadzić zarówno datę rozpoczęcia, jak i zakończenia.');
            return;
        }

        copyEventAPI.call({
            body: {
                eventElementId,
                eventElementStartDateTime: format(new Date(startDate), 'yyyy-MM-dd HH:mm'),
                eventElementEndDateTime: format(new Date(endDate), 'yyyy-MM-dd HH:mm'),
            },
        }).then((res) => {
            if (res?.response.ok) {
                setSuccessMessage('Wydarzenie zostało pomyślnie skopiowane');
                eventElementsCall.call({
                    body: {
                        eventId: eventId,
                        sortBy: sortBy,
                        pageNumber: 1,
                        elementsPerPage: isAdminOptions ? 7 : 8,
                        faculty: faculty,
                        onlyActive: !isAdminOptions,
                        filter: filter,
                    },
                }).then((refreshRes: any) => {
                    if (refreshRes?.response?.ok) {
                        setElements(refreshRes.data.data);
                    }
                });
                onCopy();
            } else if (res?.data?.error) {
                switch (res.data.error) {
                    case 'missing parameters':
                        setErrorMessage('Brakujące parametry.');
                        break;
                    case 'Invalid or past date':
                        setErrorMessage('Nieprawidłowa lub przeszła data.');
                        break;
                    default:
                        setErrorMessage('Nieznany błąd.');
                        break;
                }
            }
        }).catch(() => {
            setErrorMessage('Wystąpił problem z kopiowaniem wydarzenia.');
        });
    };

    return (
        <div className="copy-event-modal">
            <div className="copy-event-content">
                <h2>Kopiuj Element Wydarzenia: {eventElementName}</h2>
                <div className="form-group">
                    <label>Wybierz nową datę rozpoczęcia wydarzenia:</label>
                    <input
                        type="datetime-local"
                        value={startDate}
                        onChange={(e) => setStartDate(e.target.value)}
                        required
                    />
                </div>
                <div className="form-group">
                    <label>Wybierz nową datę zakończenia wydarzenia:</label>
                    <input
                        type="datetime-local"
                        value={endDate}
                        onChange={(e) => setEndDate(e.target.value)}
                        required
                    />
                </div>
                {errorMessage && <div className="error-message">{errorMessage}</div>}
                {successMessage && <div className="success-message">{successMessage}</div>}
                <div className="form-actions">
                    <button onClick={handleCopyEvent} className="submit-button">Kopiuj</button>
                    <button onClick={onClose} className="cancel-button">Anuluj</button>
                </div>
            </div>
        </div>
    );
};

export default CopyEventElement;