import { useState } from "react";
import { Modal } from "../Modal/Modal";
import { EditElementPopup } from "./EditElementPopup";
import { EventElementProps } from "./EventElementContainer";

type CreateEventElementProps = {

  elements: EventElementProps[];
  eventElementsCall: any;
  sortBy: number;
  filter: number;
  faculty: number;
  isAdminOptions: boolean;
  setElements: (ele: EventElementProps[]) => void;
  eventId: number; // Add eventId to the props

};

export const CreateEventElement = (props: CreateEventElementProps) => {
  const [visibleCreatePopup, setVisibleCreatePopup] = useState<boolean>(false);
  const token = sessionStorage.getItem("jwtToken");

  const handleCreateClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    setVisibleCreatePopup(true);
  };

  return (
      <>
        {visibleCreatePopup && (
            <Modal>
              <EditElementPopup
                  setVisiblePopup={() => setVisibleCreatePopup(false)}
                  token={token || ""}
                  elements={props.elements}
                  eventElementsCall={props.eventElementsCall}
                  sortBy={props.sortBy}
                  filter={props.filter}
                  faculty={props.faculty}
                  isAdminOptions={props.isAdminOptions}
                  setElements={props.setElements}
                  eventId={props.eventId}
                  isImportant={false} // Set isImportant to false for new events
              />
            </Modal>
        )}
        <div className="createElementContainer">
          <h1>Dodaj nowe wydarzenie</h1>
          <br />
          <button onClick={handleCreateClick}>Dodaj</button>
        </div>
      </>
  );
};