import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import "./userRegistration.style.css";
import useAPI from "../../hooks/UseAPI";
import MainPhoto from "../MainPhoto/MainPhoto";
export const UserRegistration = () => {
    const { token } = useParams();
    const [error, setError] = useState('');
    const [status, setStatus] = useState('');
    const [registrationInfo, setRegistrationInfo] = useState({ address: '', participants: '', date: '', faculty: '' });
    const statusRegistration = useAPI({
        url: `userregistration/getstatus/${token}`, 
        method: 'GET',
    }, true);
    const handleStatusRegistration = () => {
        if (token){
            statusRegistration.call().then((res) => {
                if (res.response !== undefined && res.response.ok){
                    const formattedDate = res.data.elementDateStart.date.slice(0, 16);
                    setRegistrationInfo({
                        address: `Miejsce: ${res.data.elementAddres}`,
                        participants: `Ilość uczestników: ${res.data.participants}`,
                        date: `Data rozpoczęcia: ${formattedDate}`,
                        faculty: `Wydarzenie: ${res.data.elementName}`,
                    });

                }
            })
        }
    }

    const confirmRegistration = async () => {
        try {
            const response = await fetch(`${process.env.REACT_APP_API_URL}userregistration/confirm/${token}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',

                },
            });

            const data = await response.json();
           
            if (response.ok) {
                switch(data.status) {
                    case 'User registration confirmed':
                        setStatus(`Gratulacje! Twoja rejestracja na wydarzenie została potwierdzona.`);
                        handleStatusRegistration();
                        break;
                    case 'group registration verified':
                        setStatus(`Sukces! Rejestracja grupowa została zweryfikowana. Instrukcje dotyczące dalszego postępowania zostały wysłane na Twój adres email.`);
                        handleStatusRegistration();
                        break;
                    case 'group registration already confirmed now verify':
                        setStatus("Rejestracja grupowa została potwierdzona. Prosimy sprawdzić skrzynkę e-mailową w celu uzyskania dalszych instrukcji i w razie potrzeby skontaktować się z nami telefonicznie.");
                        handleStatusRegistration();
                        break;

                    default:
                        setError('Nieznany status. Prosimy o kontakt z wsparciem technicznym.');
                }
            } else {

                switch(data.status){
                    case 'no event element found':
                        setError("Przepraszamy, wystąpił problem podczas przetwarzania Twojego żądania. Prosimy spróbować ponownie później.");
                        break;
                    case 'not enough space for participants':
                        setError("Przepraszamy, ale wszystkie miejsca na to wydarzenie zostały już zarezerwowane. Zachęcamy do odwiedzenia naszej strony z wydarzeniami i znalezienia innych atrakcji, które mogą Cię zainteresować.");
                        break;
                    case 'mailing error':
                        setError("Niestety, wystąpił problem z wysyłką wiadomości e-mail. Spróbuj ponownie później lub skontaktuj się z obsługą.");
                        break;
                    case 'no user registration found for given id':
                        setError("Nie znaleziono rejestracji dla podanego identyfikatora.");
                        break;
                    
                    default:
                        setError("Przepraszamy, wystąpił problem podczas przetwarzania Twojego żądania. Prosimy spróbować ponownie później.");

                }
            }
        } catch (error) {
            setError('Nie można nawiązać połączenia z serwerem. Sprawdź swoje połączenie internetowe i spróbuj ponownie.');
        }
    };

    useEffect(() => {
        if (token) {
            confirmRegistration();
        }
    }, [token]);

    const registrationInfoComponent = !error && (
        <>
            <div className="space"></div>
            <div className="status">Status</div>
            <div className="messageStatus">
                <div className="wydzialConf">{registrationInfo.faculty}</div>
                <div>{registrationInfo.address}</div>
                <div>{registrationInfo.participants}</div>
                <div>{registrationInfo.date}</div>
                <div className="line"></div>
            </div>
        </>
    );


    return (
        <>
        <MainPhoto/>
        <div className="contentMessage">
             
            <div className="messageCancel">{error || status}</div>
            {registrationInfoComponent}
        </div>
        </>
    );
};
