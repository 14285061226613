import React from "react";
import "../Modal/modal.style.css";

interface SmallModalProps {
    children: React.ReactNode;
}

export const SmallModal = ({ children }: SmallModalProps) => {
    return (
        <div className="smallModalBackground">
            {children}
        </div>

    );
};
