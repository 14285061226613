import { useEffect, useState } from "react";
import { EventElementProps } from "./EventElementContainer";

type DeleteElementPopupProps = {
  eventElementId: number | string;
  eventElementName: string;
  setVisiblePopup: (val: boolean) => void;
  dateStartPart: string;
  timeStartPart: string;
  timeEndPart: string;
  token: string;
  elements: any;
  setElements: (ele:any) => void
  deleteCall: any
  deleteBody?: any
  error: string
  setError: (val: string) => void
};

export const DeleteElementPopup = (props: DeleteElementPopupProps) => {

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      const loseFocusePopup = document.querySelector(
        ".deleteElementContainer"
      );
      if (loseFocusePopup && !loseFocusePopup.contains(event.target)) {
        props.setVisiblePopup(false);
        props.setError("")
      }
    };
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [props]);

  const handleDelete = () => {
    if (props.deleteBody) {
      props.deleteCall.call({
        body: {
          eventElementId: props.eventElementId
        }
      }).then((res: any) => {
        if (res !== undefined && res.response !== undefined && res.response.ok) {
          const updatedEvents = props.elements.filter((e: EventElementProps) => e.id !== props.eventElementId)
          props.setElements(updatedEvents)
          props.setVisiblePopup(false);
        } else {
          props.setError("Nie udało się usunąć")
        }
      })
    }else{
      props.deleteCall.call().then((res: any) => {
        if (res !== undefined && res.response !== undefined && res.response.ok) {
          const updatedEvents = props.elements.filter((e: any) => e.registrationData.confirmationCode !== props.eventElementId)
          props.setElements(updatedEvents)
          props.setVisiblePopup(false);
        } else {
          props.setError("Nie udało się usunąć")
        }
      })
    }

  }

  return (
    <>
      <div className="deleteElementContainer">
        <div className="titleDeleteElementPopup">
          <h1>Czy na pewno chcesz usunąć?</h1>
        </div>
        <div className="contentDeleteElementPopup">
          <h2>{props.eventElementName}</h2>
          <h3>{props.dateStartPart}</h3>
          <p>
            {props.timeStartPart.split("+")[0].substring(0, 5)} -{" "}
            {props.timeEndPart.split("+")[0].substring(0, 5)}
          </p>
        </div>
        <div className="deletePopupActions">
          <button onClick={handleDelete} style={{ backgroundColor: "rgba(166, 0, 0, 1)" }}>Usuń</button>
          <button onClick={() => props.setVisiblePopup(false)} style={{ backgroundColor: "rgba(21, 33, 61, 1)" }}>Powrót</button>
        </div>
        {props.error && <div className="error">{props.error}</div>}
      </div>
    </>
  );
};
