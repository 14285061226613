import { useEffect } from "react";
import { EventElementProps } from "./EventElementContainer";
import "./event.style.css";

type EventPopupProps = {
  seatsLeft: number | string;
  dateStartPart: string;
  timeStartPart: string;
  timeEndPart: string;
  setVisiblePopup: (val: boolean) => void;
} & EventElementProps;

export const EventElementPopup = (props: EventPopupProps) => {
  useEffect(() => {
    const loseFocusePopup = document.querySelector(".modalBackground");
    if (loseFocusePopup !== null) {
      loseFocusePopup.addEventListener("click", function () {
        props.setVisiblePopup(false);
      });
    }
  }, []);

  return (
      <>
        {props && (
            <div className="popupEventElementContainer">
              <div className="popupNavbar">
                <div></div>
                <h3 style={{ textAlign: "center" }}>
                  {props.elementName}
                </h3>
              </div>
              <div style={{ display: "flex", width: "100%" }}>
                <div className="leftSidePopup">
                  <b>{props.dateStartPart}</b>
                  <p>
                    {props.timeStartPart.split("+")[0].substring(0, 5)} -{" "}
                    {props.timeEndPart.split("+")[0].substring(0, 5)}
                  </p>
                  <br />
                  {props.typeOfElement !== "information" && (
                      <>
                        <p>Miejsc zostało: {props.seatsLeft}</p>
                        <br />
                      </>
                  )}
                  <p>Miejsce: {props.room}</p>
                </div>
                <div className="rightSidePopup">
                  <span style={{fontSize:"18px"}}>{props.elementDescription}</span>
                </div>
              </div>
            </div>
        )}
      </>
  );
};