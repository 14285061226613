import React, { useEffect, useState, useRef } from "react";
import useAPI from "../../hooks/UseAPI";
import "./groupRegistration.style.css";
import { EventElementConfirm, EventElementConfirmProps } from "./EventElementConfirm";

type GroupRegistrationPopup = {
    setVisiblePopup: (val: false) => void;
    email: string;
    notCofnrimedGroups: boolean;
    eventId: number;

};

export const GroupRegistration = (props: GroupRegistrationPopup) => {
    const [error, setError] = useState("");
    const token = sessionStorage.getItem("jwtToken");
    const [registrationData, setRegistrationData] = useState<EventElementConfirmProps[]>()
    const popupRef = useRef<HTMLDivElement>(null);

    const checkByEmail = useAPI({
        url: props.notCofnrimedGroups ? "userregistration/checkbyemailnotconfirmedgroups" : "userregistration/checkbyemail" ,
        method:"POST",
        auth:true,
        token: token ? token : "",
        body:{
            eventId: props.eventId,
            email: props.email
        }
    }, true)

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (popupRef.current && !popupRef.current.contains(event.target as Node)) {
                props.setVisiblePopup(false);
                setError("");
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [props]);

    useEffect(()=>{
        if(checkByEmail.data){
            setRegistrationData(checkByEmail.data)
        }
    },[checkByEmail.data])

    useEffect(()=>{
        if(checkByEmail.error){
            if(checkByEmail.error.status === "no registration found for given email"){
                setError("Nie znaleziono rejestracji dla podanego maila")
            }else{
                setError("Brak rejestracji")
            }
        }
    },[checkByEmail.error])

    return (
        <div className="groupRegistrationContainer" ref={popupRef}>
            <div className="groupRegistrationTitle">
                <h1>Lista grup użytkownika</h1>
            </div>
            <div className="groupRegistrationContent">
                <h3>{props.email}</h3>
                {registrationData &&
                    registrationData.map((element: EventElementConfirmProps, index: number) => {
                        if(element.eventElementData !== null){
                            return (
                                <div key={index}>
                                    <EventElementConfirm
                                        {...element}
                                        data={registrationData}
                                        setData={setRegistrationData}
                                    />
                                </div>
                            );
                        }
                    })}
            </div>
            {error && <div className="error">{error}</div>}
        </div>
    );
};