import React from 'react';
import './notification.style.css';

interface NotificationProps {
    content: string;
}

const Notification: React.FC<NotificationProps> = ({ content }) => {
    if (!content) return null;

    return (
        <div className="notification-display" dangerouslySetInnerHTML={{ __html: content }} />
    );
};

export default Notification;