import React, { useState } from "react";
import malpaIcon from '../../../assets/Email_login.png';
import '../PopUpPasswordReminder/popUpPasswordReminder.style.css';
import useAPI from "../../../../hooks/UseAPI";
import { useNavigate } from 'react-router-dom';
export const PopUpPasswordReminder = () => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const navigate = useNavigate();
    const passReminder = useAPI({
        url: 'user/requestresetpassword',
        method: 'POST'
    });

    
    const validateEmail = (email:string) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\\.,;:\s@"]+\.)+[^<>()[\]\\.,;:\s@"]{2,})$/i;
        return re.test(String(email).toLowerCase());
    };
    
    const handleReminderSubmit = async (e: React.FormEvent) => {
        e.preventDefault();
        
        if (!validateEmail(email)) {
            setError("Podany format adresu E-mail jest nie prawidłowy.");
            setSuccessMessage('');
            return; 
        }

        passReminder.call({
            body: {
                email: email,
            }
        })
        .then((res) => {
            if(res?.response.ok) {
                setError('');
                setSuccessMessage("Link do resetowania hasła powinien trafić na twoją skrzynkę pocztową");
            } else {
                setSuccessMessage('');
                setError("Nie znaleziono twojego maila");
            }
        })
        .catch(() => {
            setError("Wystąpił błąd przy wysyłaniu przypomnienia hasła.");
        });
    };
    const handleBackToLogin = () => {
        window.location.reload();
    }

    return (
        <div className="popup-content-reminder">
            <div className="passwordReminderForm">
                <form onSubmit={handleReminderSubmit}>
                    <h2>Przypomnij Hasło</h2>
                    {error && <div className="Email-error">{error}</div>}
                    {successMessage && <div className="Email-success">{successMessage}</div>}
                    <div className="input-box">
                        <input type="text" placeholder="E-mail" required value={email} onChange={(e) => setEmail(e.target.value)}/>
                    </div>
                    <div className="reset-instruction">
                        <label className="reset">Link do resetowania hasła zostanie wysłany na e-mail przypisany do konta</label>
                    </div>
                    <button type="submit" className="btn">Przypomnij Hasło</button>
                    
                    
                </form>
                <button type="submit" className="btnBack" onClick={handleBackToLogin}>Wróc do logowania</button>
            </div>
        </div>
    );
};
